import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import logo from "./assets/Logo.png";
import logo2 from "./assets/shopezlogo.png";
import { Badge } from 'primereact/badge';
import { UPDATE_USERNAME } from './redux/slices/authenticationSlice';
import { useDispatch, useSelector } from 'react-redux';

export const AppTopbar = (props) => {

    const dispatch = useDispatch()
    const userName = useSelector((state) => state.authenticationSlice.username) || localStorage.getItem("username");

    useEffect(() => {
        if (userName) {
            dispatch(UPDATE_USERNAME(userName));
        }
    }, [dispatch, userName]);

    const handleLogout = async () => {
        localStorage.clear();
        window.localStorage.clear();
        window.location.reload();
    };

    const handleModuleClick = () => {
        setShowTopBar(true);
    };

    const [showTopBar, setShowTopBar] = useState(false);
    //Custom Dropdown
    const [open, setOpen] = useState(false);
    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);
                //console.log(menuRef.current);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }

    });

    const handleProfileClick = () => {
        // Handle clicking on the profile option
        // You can navigate to the user profile page or perform other actions
    };
    return (


        <div className="layout-topbar">
            {/* <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? logo : logo} alt="logo" />
            </Link> */}
            <Link to="/" className="layout-topbar-logo2">
                <img src={props.layoutColorMode === 'light' ? logo2 : logo2} alt="logo" />
            </Link>

            <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars ml-3" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>

                <div className='menu-container' ref={menuRef}>
                    <div className='menu-trigger' onClick={() => { setOpen(!open) }}>
                        <div className='flex align-items-center'>
                            <Badge severity='success' value={`${userName}`} style={{ fontSize: "0.9rem" }} className="mr-3" />
                            <i className='pi pi-angle-down'></i>
                        </div>
                    </div>

                    <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`} >
                        <ul>
                            <li className='dropdownItem' >
                                <div><a className="color" onClick={handleLogout}> <b className='pi pi-sign-out mr-3'></b><b style={{cursor:"pointer"}}>Logout</b> </a></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </ul>

        </div>
    );
}
