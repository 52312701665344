import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../service/PostTemplate";
import { useDispatch } from "react-redux";
import { handlePatchRequest } from "../../service/PatchTemplete";

const AddEdit = ({ getTaxList, onHide, taxRowData2, addEditTax2 }) => {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        taxType: Yup.mixed().required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            taxType: "",
        },
        onSubmit: async (data) => {
            setLoading(true);

            if (addEditTax2 === true) {
                const obj = {
                    taxType: formik.values.taxType, 
                    taxTypeId: taxRowData2?._id, 
                }

                const res = await dispatch(handlePatchRequest(obj, "api/v1/tax/type/", true, true));
                onHide();
                setLoading(false);
                await getTaxList();
                getTaxList();
            }
            else {

                const res = await dispatch(handlePostRequest(data, "api/v1/tax/type", true, true));
                onHide();
                setLoading(false);
                await getTaxList();
                getTaxList();
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {

        if (taxRowData2 && addEditTax2) {
            formik.setFieldValue("taxType", taxRowData2?.taxType)
        }

    }, [addEditTax2, taxRowData2])

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid p-p-3">
                    <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                        <div className="flex flex-column">
                            <label className="mb-2">
                                Tax Type <span style={{ color: "red" }}>*</span>
                            </label>
                            <InputText  name="taxType" id="taxType" maxLength={25} value={formik?.values?.taxType} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("taxType") }, "w-full md:w-10 inputClass")} />
                            {getFormErrorMessage("taxType")}
                        </div>
                    </div>

                    <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                        <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                        <Button
                            disabled={!/[a-zA-Z0-9]/.test(formik?.values?.taxType)}
                            autoFocus
                            className="Savebtn"
                            label={addEditTax2 ? "Update" : "Save"}
                            type="submit"
                            loading={loading}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddEdit;
