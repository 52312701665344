import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Link, useLocation } from "react-router-dom";
import Moment from "moment";
import { handleGetRequest } from "../../service/GetTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import AddEditReturn from "./AddEditReturn";
import { useHistory } from "react-router-dom";
import OrderRetured from "./OrderRetured";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";

const ManageReturn = () => {


    let { search, state } = useLocation();
    const [displayDialog, setDisplayDialog] = useState(false);
    // console.log("statssae", state?.id)
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editable, setEditable] = useState(false);
    const [returnorderData, setReturnOrderData] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [loading, setloading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [orderRowData, setOrderRowData] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const onHide = () => {
        setEditable(false);
        setVisibleEdit(false);
    };

    const onHide3 = () => {
        setDisplayDialog(false);
    };

    const getReturnOrderData = async () => {
        setloading(true);
        const res = await handleGetRequest("api/v1/returnOrder/list", false);
        if (res) {
            setReturnOrderData(res);
        }
        setloading(false);
    };
    useEffect(() => {
        getReturnOrderData();
    }, []);

    const actionTemplate = (rowData) => {
        localStorage.setItem('orderId', rowData?.orderId?._id)
        return (
            <div className="Edit_Iconn">
                <Button
                    tooltip="Details"
                    label="Details"
                    tooltipOptions={{ position: "top" }} className='btn btn-info ml-auto'
                    onClick={() => history.push({
                        pathname: '/detailreturnordermanagement',
                        search: `?orderid=${rowData?.orderId?._id}`,
                        state: { id: rowData?.orderId?._id, pageOrderId: rowData?.orderId?.orderId }
                    }
                    )} />
            </div>
        );
    };

    const statusTemplete = (rowData) => {
        const status = rowData?.orderId?.status;
        return <span style={{ color: status === 'Return' ? 'red' : 'black' }}>{status === 'Return' ? 'Requested for return' : status}</span>;
    }

    const OrderIdClickable = (rowData) => {

        return (
            <React.Fragment>
                <Link to={`./detailreturnordermanagement?orderid=${rowData?.orderId?._id}`}>{rowData?.orderId?.orderId}</Link>
            </React.Fragment>
        );
    };
    const customerNameTemplete = (rowData) => {
        let customerName = "";
        if (rowData?.orderId?.customer?.firstName) {
            customerName = rowData?.orderId?.customer?.firstName + " " + rowData?.orderId?.customer?.lastName;

            return <React.Fragment>{customerName}</React.Fragment>
        }
    };
    const orderDateTemplete = (rowData) => {

        const originalDate = rowData?.createdAt;
        const originalMoment = Moment.utc(originalDate);
        const newMoment = originalMoment.add(5, 'hours');
        const formattedDate = newMoment.format("DD-MM-YYYY hh:mm a");

        return (
            <React.Fragment>
                <span>{formattedDate}</span>
            </React.Fragment>
        );
    };

    const confirm2 = (rowData) => {
        setOrderRowData(rowData._id);
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-trash",
            acceptClassName: "Savebtn",
            rejectClassName: "Cancelbtn",
            accept,
            reject,
        });
    };

    const accept = () => {
        setVisibleDelete(true);
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    };

    const reject = () => {
        setVisibleDelete(false);
        // toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };
    const sidebarHeader = () => {
        return (
            <div className="card-header">
                <label>DETAILS</label>
            </div>
        );
    };
    const history = useHistory();
    const toast = useRef(null);

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions.modules.find(module => module.module.route.includes("/returnmanagement"));
        if (allPermModules) {
            setPermissions(allPermModules?.permissions?.map(item => item?.name) || []);
        }
    }, []);


    return (
        <>
            <Dialog header="Order Returned" visible={displayDialog} style={{ width: "40vw" }} onHide={onHide3}>
                <OrderRetured
                    id={state?.id}
                    onHide={onHide3}
                    getReturnOrderData={getReturnOrderData}
                />

            </Dialog>
            <Toast ref={toast} />
            <Sidebar header={sidebarHeader} position="right" className="w-full" visible={visibleEdit} onHide={onHide}>
                <AddEditReturn
                    getReturnOrderData={getReturnOrderData}
                    editable={editable}
                    onHide={onHide}
                    orderRowData={orderRowData}
                />
            </Sidebar>
            <div className="grid">
                <div className="col-12  md:col-12 lg:col-12 xl:col-12">
                    <div className="text-right">
                        <span className="p-input-icon-right mr-3">
                            <input type="text" placeholder="Search" onInput={(e) => setGlobalFilter(e.target.value)} className="p-inputtext p-component p-filled" />
                            <i className="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                    <div className="innr-Body">
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            rows={10}
                            paginator
                            responsiveLayout="scroll"
                            value={returnorderData}
                            globalFilter={globalFilter}
                            globalFilterFields={["orderId.orderId", "orderId.customer.firstName", "orderId.customer.lastName"]}
                        >
                            <Column body={OrderIdClickable} header="Order ID" />
                            <Column body={customerNameTemplete} field="customer.firstName" header="Customer's Name" />
                            <Column body={orderDateTemplete} header="Return Date" />
                            <Column body={statusTemplete} header="Status" />
                            {permissions.includes("manage") ?
                                <Column body={actionTemplate} header="Detail" />
                                :
                                null
                            }
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageReturn
