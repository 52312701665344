import { baseURL } from "../utilities/Config";
import axios from "axios";
import { toast } from "react-toastify";
import { Toast } from 'primereact/toast';


export const handleGetRequest = async (url, isShowToast) => {
    const abc = localStorage.getItem("token");
    console.log('abc', abc)
    try {
        const response = await axios.get(`${baseURL + url}`, {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        // if (isShowToast) toast.success(response?.data?.msg, { autoClose: 1500 });
        return response?.status === 200 || response?.status === 201 ? (response?.data?.data) : [];
    } catch (error) {
    }
};
