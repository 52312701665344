import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { handleGetRequest } from "../service/GetTemplate";
import moment, { months } from "moment";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

const Dashboard = (props) => {

    const [lineData, setLineData] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [deliverddata, setDeliverdData] = useState(null);
    const [loading, setLoading] = useState(null);

    const presentDate = moment().format('YYYY-MM-DD');
    const calculatedEndDate = moment(presentDate).subtract(30, 'days').format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState(calculatedEndDate);
    const [endDate, setEndDate] = useState(presentDate);

    const getlinedata = async () => {
        
        const res = await handleGetRequest("api/v1/order/hotSellingProducts", false, true).then((res) => {
            let lables = res && res.map((item) => item?.productName || '');
            let values = res && res.map((item) => item.count);
            let data = {
                labels: lables,
                datasets: [
                    {
                        label: "Sold",
                        data: values,
                        fill: false,
                        borderColor: "#63C56E",
                        tension: 0.4,
                    },
                ],
            };
            setLineData(data);
        });

        if (res === 200) {
            setLineData(res?.data?.data);
        }
    };

    useEffect(() => {
        getlinedata();
    }, []);

    // Get Line chart Data
    const getOrderData = async () => {
        const res = await handleGetRequest("api/v1/order/dashboard", false, true);
        if (res) {
            setOrderData(res);
        }
    };

    useEffect(() => {
        getOrderData();
        // getDeliverdData();
    }, []);

    // integrate delivered chart
    const getDeliverdData = async () => {

        const res = await handleGetRequest("api/v1/order/orderReport", true);

        let data = {
            labels: ['Year 2024'],
            datasets: [
                {
                    label: "Total Delivered",
                    backgroundColor: "#63c56e",
                    data: [Math.max(Math.floor(parseInt(res[0]?.total) || 0))],
                },
                {
                    label: "Total Returned",
                    backgroundColor: "#b66fef",
                    data: [Math.max(Math.floor(parseInt(res[1]?.total) || 0))],
                },
                {
                    label: "Total Order",
                    backgroundColor: "#0db2c5",
                    data: [Math.max(Math.floor(parseInt(res[2]?.totalOrder) || 0))],
                },
            ],
        };

        setDeliverdData(data);
    };

    const getReportByDate = async () => {

        const res = await handleGetRequest(`api/v1/order/orderReport?startDate=${startDate}&endDate=${endDate}`, false);
    
        const Pending = res?.filter(item => item?.status === 'Pending')
        const Returnn = res?.filter(item => item?.status === 'Return')
        const Returned = res?.filter(item => item?.status === 'Returned')
        const Delivered = res?.filter(item => item?.status === 'Delivered')
        const Total = res?.filter(item => !item?.status)

        if (res) {
            let data = {
                labels: [''],
                datasets: [
                    {
                        label: "Pending",
                        backgroundColor: "#e0bd47",
                        data: [Pending[0]?.total || 0],
                    },
                    {
                        label: "Delivered",
                        backgroundColor: "#63c56e",
                        data: [Delivered[0]?.total || 0],
                    },
                    {
                        label: "Return Request",
                        backgroundColor: "#f53939",
                        data: [Returnn[0]?.total || 0],
                    },
                    {
                        label: "Returned",
                        backgroundColor: "#b66fef",
                        data: [Returned[0]?.total || 0],
                    },
                    {
                        label: "Total Order",
                        backgroundColor: "#0db2c5",
                        data: [Total[0]?.totalOrder || 0],
                    },
                ],
            };
            setDeliverdData(data);
        }
    };

    useEffect(() => {

        if (startDate && endDate) {
            getReportByDate()
        }
    }, [startDate, endDate])

    useEffect(() => {
        setLoading(true);

        // Set loading to false after 2 seconds
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);

        // Clear timeout to avoid memory leaks
        return () => clearTimeout(timeout);
    }, []);

    return (

        <>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <div>
                    <div className="grid" style={{justifyContent:"space-between"}}>
                        {orderData &&
                            orderData.map((item) => (
                                <div className="col-12 lg:col-6 xl:col-2">
                                    <div className={`card mb-0 tab_${item?.status} m_height`}>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block font-medium mb-3 tab_text" style={{fontSize: item?.status === 'Return' ? "16px" : "16px"}}>{item?.status === 'Return' ? 'Return Request' : item?.status != undefined ? item?.status : "Total Order"}</span>
                                                <div className="text-900 font-medium text-xl numbr_size">{item?.order != undefined ? item?.order : item.totalOrder}</div>
                                            </div>
                                            <div className={`flex align-items-center justify-content-center bg-blue-100 border-round icon_style_${item?.status} icon_size`}>
                                                <i className="pi pi-shopping-cart text-white-500 text-xl" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="grid mt-5">
                        <div className="col-12 lg:col-6 xl:col-6">
                            <div className="card">
                                    <div className="grid">
                                        <div className="col-12 md:col-7">
                                            <span className="block mb-3 mt-3 label_text">Orders</span>
                                        </div>
                                    </div>
                                <div className="flex flex-row mb-4" >
                                    <div className="flex flex-column">
                                        <label htmlFor="fromDate">
                                            Start Date
                                        </label>
                                        <InputText
                                            type="date"
                                            id="startDate"
                                            name="startDate"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                            className="w-full md:w-10 inputClass"
                                        />
                                    </div>
                                    <div className="ml-4 flex flex-column">
                                        <label htmlFor="fromDate">
                                            End Date
                                        </label>
                                        <InputText
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            min={startDate !== '' ? startDate : new Date().toISOString().split('T')[0]}
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                            className="w-full md:w-10 inputClass"
                                        />
                                    </div>
                                </div>
                                <Chart type="bar" data={deliverddata} options={deliverddata} />
                            </div>
                        </div>

                        <div className="col-12 lg:col-6 xl:col-6">
                            <div className="card">
                                <div style={{marginBottom:"72px"}}>
                                    <div className="grid">
                                        <div className="col-12 md:col-7">
                                            <div>
                                                <span className="block mb-3 mt-3 label_text">Top Selling Products</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Chart type="line" data={lineData} options={lineData} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
