import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { handleGetRequest } from "../../service/GetTemplate";
import { handleDeleteRequest } from "../../service/DeleteTemplete";
import { useDispatch } from "react-redux";
import Edit from "../../../src/assets/ICONS/icon_edit.png";
import Delete from "../../../src/assets/ICONS/icon_delete.png";
import AddEditDeliveryCharges from "./AddEditDeliveryCharges";

const DeliveryCharges = () => {
    
    const dispatch = useDispatch();
    const [displayBasic, setDisplayBasic] = useState(false);
    const [deliveyData, setDeliveryData] = useState([]);
    const [deliveyRowData, setDeliveyRowData] = useState(null);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [addEditDelivery, setaddEditDelivery] = useState(null);
    const [permissions, setPermissions] = useState([]);

    const dialogFuncMap = {
        displayBasic: setDisplayBasic,
    };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const getDeliveryData = async () => {
        const res = await handleGetRequest("api/v1/deliveryCharges/", false);
        if (res) {
            setDeliveryData(res);
        }
    };
    useEffect(() => {
        getDeliveryData();
    }, []);


    const onHide = () => {
        setDisplayBasic(false);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit p-mr-2" onClick={() => editCharges(rowData)}>
                    <img src={Edit} />
                </Button>
                {/* <Button
                    tooltip="Delete"
                    tooltipOptions={{ position: "top" }}
                    className="delete p-mr-2 p-ml-3"
                    onClick={() => {
                        confirm2(rowData);
                    }}
                >
                    <img src={Delete} />
                </Button> */}
            </div>
        );
    };

    const editCharges = (rowData) => {
        setDisplayBasic(true);
        setaddEditDelivery(true);
        setDeliveyRowData(rowData);
    };

    const confirm2 = (rowData) => {

        confirmDialog({
            message: "Are you sure you want to delete this Delivery Charges ?",
            header: "Delete Confirmation",
            icon: "pi pi-trash",
            acceptClassName: "Savebtn",
            rejectClassName: "Cancelbtn",
            accept: () => accept(rowData._id), 
            reject,
        });
    };

    const accept = async (id) => {

        const obj ={
          _id:  id,
        }

        const res = await dispatch(handleDeleteRequest(obj, `api/v1/deliveryCharges/`, true, true));
        if (res.status === 200) {
            getDeliveryData();
        }

    };

    const reject = () => {
       return null
    };
    
    const toast = useRef(null);

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const taxesManagementModule = myPermissions.modules.find(module => module.module.route.includes("/deliverycharges"));
        if (taxesManagementModule) {
            setPermissions(taxesManagementModule?.permissions?.map(item => item?.name) || []);
        }
    }, []);

    return (
        <>
            <Toast ref={toast} />
            <Dialog header={addEditDelivery ? "Edit" : "Add Delivery Charges"} visible={displayBasic} style={{ width: "40vw" }} onHide={onHide}>
                <AddEditDeliveryCharges onHide={onHide} getDeliveryData={getDeliveryData} addEditDelivery={addEditDelivery} deliveyRowData={deliveyRowData} />
            </Dialog>

            <div className="grid">
                <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                    <div className="text-right">
                        <span class="p-input-icon-right mr-3">
                            <input type="text" placeholder="Search" class="p-inputtext p-component p-filled" onInput={(e) => setGlobalFilter(e.target.value)} />
                            <i class="pi pi-search"></i>
                        </span>
                        {/* {permissions?.includes("create") ?
                            <button
                                className="p-button p1-button-primary p-component mr-2"
                                onClick={() => {
                                    setaddEditDelivery(false);
                                    onClick("displayBasic");
                                }}
                            >
                                <span className="p-button-icon p-c p-button-icon-left pi pi-plus"></span>
                                <span className="p-button-label p-c">Add New</span>
                                <span className="p-ink"></span>
                            </button> : null
                        } */}

                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                    <div className="innr-Body">
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            rows={7}
                            paginator
                            responsiveLayout="scroll"
                            value={deliveyData}
                            globalFilterFields={["Region.taxType", "deliveryCharges"]}
                        >
                            <Column field="Region.taxType" header="Region" />
                            <Column field="deliveryCharges" header="Delivery Charges" />
                            {permissions.includes("manage") ?
                                <Column body={actionTemplate} header="Action" />
                                :
                                null
                            }
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeliveryCharges;
