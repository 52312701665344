import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../service/GetTemplate";
import { handlePostRequest } from "../../../service/PostTemplate";
import { handlePatchRequest } from "../../../service/PatchTemplete";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import AddEditImage from "../../../components/AddEditImage";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from 'primereact/progressspinner';

const Addedit = ({ onHide, getCoupandata, addEditCoupan, coupanRowData }) => {

    const [loading, setloading] = useState(false);
    const [fileUploadData, setfileUploadData] = useState("");
    const [apiData, setApiData] = useState();
    const [btnDisable, setBtnDisable] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        couponCode: Yup.string().required("This field is required"),
        image: Yup.mixed().required("This field is required."),
        expireDate: Yup.string().required("This field is required"),
        orderPriceLimit: Yup.mixed().required("This field is required."),
        couponValue: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            couponCode: "",
            image: "",
            expireDate: "",
            orderPriceLimit: "",
            couponValue: "",
            // activeFrom: "",
            // activeTo: "",
            isActive: Yup.boolean,
            isPercentage: Yup.boolean,
        },
        onSubmit: async (data) => {

            setloading(true);
            if (addEditCoupan === true) {
                data["image"] = fileUploadData;
                data["couponId"] = coupanRowData;
                const expireDateUtc = new Date(formik.values.expireDate);
                const adjustedExpireDate = new Date(expireDateUtc.getTime() + 300 * 60 * 1000);
                data["expireDate"] = adjustedExpireDate
                const res = await dispatch(handlePatchRequest(data, "api/v1/coupon", true, true));
                if (res.status === 200) {
                    await getCoupandata();
                    formik.resetForm();
                    onHide();
                }
            } else {
                data["image"] = fileUploadData;
                const expireDateUtc = new Date(formik.values.expireDate);
                const adjustedExpireDate = new Date(expireDateUtc.getTime() + 300 * 60 * 1000);
                data["expireDate"] = adjustedExpireDate
                const res = await dispatch(handlePostRequest(data, "api/v1/coupon", true, true));
                if (res?.status === 200 || res?.status === 201) {
                    await getCoupandata();
                    formik.resetForm();
                    onHide();
                }
            }
            setloading(false);
        },
    });

    const getMembersByID = async () => {
        const res = await handleGetRequest(`api/v1/coupon/getOne?couponId=${coupanRowData}`, true);
        setloading(false);
        if (res) {
            const keyData = res;
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            const expireDateUtc = new Date(res?.expireDate);
            const adjustedExpireDate = new Date(expireDateUtc.getTime() - 300 * 60 * 1000);
            formik.setFieldValue("expireDate", adjustedExpireDate);
            setApiData(keyData)
        }
    };

    useEffect(() => {
        if (apiData && addEditCoupan) {
            if (apiData?.image == formik.values.image && apiData?.couponCode == formik.values.couponCode && apiData?.isActive == formik.values.isActive && moment(apiData?.expireDate).subtract(5, 'hours').format("DD MM YYYY HH:mm") == moment(formik.values.expireDate).format("DD MM YYYY HH:mm") && apiData?.couponValue == formik.values.couponValue && apiData?.orderPriceLimit == formik.values.orderPriceLimit) {
                setBtnDisable(true)
            }
          else if (apiData?.image !== formik.values.image || apiData?.couponCode !== formik.values.couponCode || apiData?.isActive !== formik.values.isActive || moment(apiData?.expireDate).subtract(5, 'hours').format("DD MM YYYY HH:mm") !== moment(formik.values.expireDate).format("DD MM YYYY HH:mm") || apiData?.couponValue !== formik.values.couponValue || apiData?.orderPriceLimit !== formik.values.orderPriceLimit) {
                setBtnDisable(false)
            }
        }

    }, [addEditCoupan, apiData, formik.values])

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (coupanRowData !== undefined && coupanRowData !== null && addEditCoupan === true) {
            getMembersByID();
        }
    }, []);

    const handleImages = (images) => {
        formik.setFieldValue("image", images);
        setfileUploadData(images);
    };

    return (

        <div>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Coupon Code <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText maxLength={10} name="couponCode" id="couponCode" className={classNames({ "p-invalid": isFormFieldValid("couponCode") }, "w-full md:w-10 inputClass")} value={formik.values.couponCode} onChange={formik.handleChange} placeholder="" />
                            </div>
                            {getFormErrorMessage("couponCode")}
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Upload Voucher <span style={{ color: "red" }}>*</span>
                                </label>
                                <AddEditImage handleImages={handleImages} editable={addEditCoupan} EditIconImage={formik?.values?.image} />
                            </div>
                            {getFormErrorMessage("image")}
                        </div>

                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label htmlFor="fromDate">
                                    Expire Date <span style={{ color: "red" }}>*</span>
                                </label>
                                <Calendar
                                    id="expireDate"
                                    name="expireDate"
                                    minDate={new Date()}
                                    value={formik.values.expireDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("expireDate") }, "w-full md:w-10 inputClass")}
                                    optionlabel="name"
                                    showTime
                                    hourFormat="24"
                                />
                            </div>
                            {getFormErrorMessage("expireDate")}
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Minimum Price Limit <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText maxLength={10} name="orderPriceLimit" id="orderPriceLimit" className={classNames({ "p-invalid": isFormFieldValid("orderPriceLimit") }, "w-full md:w-10 inputClass")} value={formik.values.orderPriceLimit} onChange={formik.handleChange} placeholder="" />
                            </div>
                            {getFormErrorMessage("orderPriceLimit")}
                        </div>

                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">Is Active</label>
                                <Checkbox id="isActive" name="isActive" inputId="binary" checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                {getFormErrorMessage("isActive")}
                            </div>
                        </div>

                        <div className="col-12 flex innr_padding">
                            <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Coupon Value <span style={{ color: "red" }}>*</span>{" "}
                                    </label>
                                    <InputText
                                        maxLength={10}
                                        name="couponValue"
                                        id="couponValue"
                                        //type="number"
                                        keyfilter={/^[0-9.!@#$%^&*]+$/}
                                        className={classNames({ "p-invalid": isFormFieldValid("couponValue") }, "w-full md:w-10 inputClass")}
                                        value={formik.values.couponValue}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                {getFormErrorMessage("couponValue")}
                            </div>
                        </div>

                        <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                            <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                            <Button
                                // autoFocus
                                className="Savebtn"
                                label={addEditCoupan ? "Update" : "Save"}
                                disabled={loading || btnDisable}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Addedit;
