import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation, Switch, Redirect, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
// import Login from "./pages/Login/Login";
import Forget from "./pages/Login/ForgetPassword";
import OTP from "./pages/Login/OTPView";

import ResetPass from "./pages/Login/ResetPass";

import Dashboard from "./components/Dashboard";
// import Login from "./pages/Login/Login";
import UserMangement from "./pages/UserManagement/Index";

import TaxesManagement from "./pages/TaxesManagement/ManageTax";
import SaleManagement from "./pages/SaleManagement/Index";
import DiscountManage from "./pages/DiscountManage/Index";
//import PromotionManagement from "./pages/PromotionManagement/CampaignManagement"
// import PromotionManagement from "./pages/PromotionManagement/Index";
//import ProductManagement from "./pages/SaleManagement/ProductManagement/ProductManagement";
import AddEditCampaign from "./pages/PromotionManagement/AddEditCampaign";
import CustomerManagement from "./pages/CustomerManagement/Index";
import InventoryStatus from "./pages/InventoryStatus/Index";
import CustomerMangement from "./pages/RegisteredUsers/CustomerManagement";
import CustomerDetails from "./pages/RegisteredUsers/CustomerDetails";
import ReviewsManagement from "./pages/ReviewsManagement/index";
import Feedback from "./pages/Feedback/Index";
import CustomerCare from "./pages/CustomerCare/Index";
import ShipmentManagement from "./pages/ShipmentManagement/Index";
import DeliveryManagement from "./pages/DeliveryManagement/DeliveryManage";
import CreateDeliveryManagement from "./pages/DeliveryManagement/DeliverySubmenu/AddEditDelivery";
import EditDeliveryManagement from "./pages/DeliveryManagement/DeliverySubmenu/Edit";
import OrderManagement from "./pages/OrderManagement/OrderManagement";
import AddEditOrderManagement from "./pages/OrderManagement/AddEditOrderManagement";
import AddEditReturn from "./pages/ReturnPolicy/AddEditReturn";
import UploadBulkProducts from "./pages/UploadBulkProducts/UploadBulkProducts";
import "react-toastify/dist/ReactToastify.css";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ToastContainer, toast } from "react-toastify";
//import GlobalLoader from "./utilities/loader";
// import LoadingOverlay from "react-loading-overlay";
// import { useSelector } from "react-redux";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Login from "./pages/Login/Login";
import ManageDeal from "./pages/ToDayDeal/ManageDeal";
import PromotionManagment from "./pages/PromotionManagement/PromotionManagement";
//import AddEditManagePromtions from "./pages/PromotionManagement/AddEditPromotionManagement";
import AddEditPromtionManagement from "./pages/PromotionManagement/AddEditPromotionManagement";
// import Forgot from "./pages/ForgotPassword/ForgotPassword"
// import ResetPassword from "./pages/ResetPassword/ResetPassword"
import CampaignManagement from "./pages/PromotionManagement/CampaignManagement";
import ReturnManage from "./pages/ReturnPolicy/ManageReturn";
import SubCategoryManagement from "./pages/SaleManagement/subCategoryManagement/subCategoryManagement";
import ReviewsDetail from "./pages/ReviewsManagement/ReviewsDetail";
import AssignRole from "./pages/AssignRole/AssignRole";
import DeliveryCharges from "./pages/DeliveryCharges.js/DeliveryCharges";

const App = () => {

    const [storedEmail, setStoredEmail] = useState("");
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const auth = useSelector((state) => state?.authenticationSlice?.token);
    const nav = useSelector((state) => state?.authenticationSlice?.nav);
    const isLoading = useSelector((state) => state?.utilitySlice?.isLoading);
    const isActive = useSelector((state) => state?.authenticationSlice?.isActive);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [isLogin, SetIsLogin] = useState();
    const [sideBar, setSideBar] = useState([]);
    const history = useHistory()

    const login = localStorage.getItem('login')
    const permissions = localStorage.getItem('permissions')
    const authState = useSelector((state) => state.authenticationSlice);

    const [dynamicMenu, setDynamicMenu] = useState([
        {
            items: [{ label: "Dashboard", icon: "pi pi-table", to: "/" }],
        },
    ]);

    useEffect(() => {
        if (authState?.permissions) {
            getPermissions();
        }
    }, [authState?.permissions]);

    require("dotenv").config();
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const getPermissions = async () => {

        const permissions = localStorage.getItem("permissions");
        if (permissions === null || permissions === undefined || permissions === "undefined") {
            return;
        }

        const perms = JSON.parse(permissions && permissions);

        let result =
            perms?.modules &&
            perms?.modules.map((item) => {
                return {
                    label: item.module?.label,
                    to: item?.module?.route,
                    items: mapChilds(item.subModule),
                    icon: item?.module?.icon,
                };
            });

        result = result?.filter((item) => item?.items === undefined);
        setDynamicMenu((prev) => {
            return [
                ...prev,
                {
                    items: result,
                },
            ];
        });
    };

    const mapChilds = (list) => {
        return list?.map((item) => {
            return {
                label: item.name,
                route: item.route,
                icon: item.icon,
            };
        });
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    useEffect(() => {
        getPermissions();
    }, []);

    // useEffect(() => {
    //     let loggedInUser = localStorage.getItem("userData");
    //     if (loggedInUser) {
    //         loggedInUser = JSON.parse(loggedInUser);
    //         console.log(loggedInUser);
    //     }
    // }, []);

    function loginResponse(args) {
        setSideBar(args);
    }

    const intervalRef = useRef();

    // Set up a function that checks the time since the user's last activity
    function checkActivity() {
        if (localStorage.getItem("login") === "false" || localStorage.getItem("login") === null) {
            return;
        }

        const lastActive = localStorage.getItem('lastActive');
        const elapsed = Date.now() - lastActive;
        if (elapsed > 1800000) { // 30 minutes in milliseconds

            console.log("one minute complete inside")
            localStorage.removeItem('lastActive');
            localStorage.removeItem("permissions");
            localStorage.removeItem("login");
            localStorage.removeItem("user");
            localStorage.removeItem("roleName");
            localStorage.removeItem("username");
            window.localStorage.clear();
            localStorage.clear();
            history.push("/");
            clearInterval(intervalRef.current);
        }
    }

    // Set up an interval to run the checkActivity function every 30 minutes
    useEffect(() => {
        intervalRef.current = setInterval(checkActivity, 1800000);
        return () => clearInterval(intervalRef.current);
    }, []);

    // Update the user's last active time whenever they interact with the page
    useEffect(() => {
        if (login) {

            function handleActivity() {
                localStorage.setItem('lastActive', Date.now());
            }

            window.addEventListener('click', handleActivity);
            window.addEventListener('keydown', handleActivity);
            window.addEventListener('mousemove', handleActivity);
            window.addEventListener('mousedown', handleActivity);
            window.addEventListener('scroll', handleActivity);
            window.addEventListener('keypress', handleActivity);

            return () => {
                window.removeEventListener('click', handleActivity);
                window.removeEventListener('keydown', handleActivity);
                window.removeEventListener('mousemove', handleActivity);
                window.removeEventListener('mousedown', handleActivity);
                window.removeEventListener('scroll', handleActivity);
                window.removeEventListener('keypress', handleActivity);
            };
        }
    }, [login]);


    useEffect(() => {
        if (!login || login === null) {
            localStorage.removeItem('lastActive');
        }
    }, [])

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {/* <GlobalLoader isShow={isLoading} /> */}
            <ToastContainer />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            {!localStorage.getItem("login") ? (
                <div>
                    {/* <Route path="/" component={Login} /> */}
                    <Switch>
                        <Route exact path="/" render={() => <Login />} />
                        <Route exact path="/forgetpassword" render={() => <Forget setStoredEmail={setStoredEmail} />} />
                        <Route exact path="/OTPView/:email" render={() => <OTP storedEmail={storedEmail} />} />
                        <Route exact path="/resetpass/:email" render={() => <ResetPass storedEmail={storedEmail} />} />
                        <Route path="/" render={() => <Login loginResponse={loginResponse} />} />
                        <Redirect to="/" />
                    </Switch>
                </div>
            ) : (
                <>
                    <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={dynamicMenu.slice(0, 2)} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </div>

                    <div className="layout-main-container">
                        <div className="layout-main">
                            <Switch>
                                <Route exact path="/" render={() => <Dashboard />} />
                                {/* <Route exact path="/login" render={() => <Login />} /> */}
                                <Route exact path="/usermanagement" element={<UserMangement />} render={() => <UserMangement />} />
                                <Route exact path="/taxesmanagement" render={() => <TaxesManagement />} />
                                <Route exact path="/salemanagement" render={() => <SaleManagement />} />
                                <Route exact path="/subcategorymanage" render={() => <SubCategoryManagement />} />
                                <Route exact path="/discountmanage" render={() => <DiscountManage />} />
                                <Route exact path="/promotionmanagement" render={() => <CampaignManagement />} />
                                <Route exact path="/promotiondetail" render={() => <AddEditCampaign />} />
                                <Route exact path="/managepromotion/:id" render={() => <PromotionManagment />} />
                                <Route exact path="/managepromotiondetail" render={() => <AddEditPromtionManagement />} />
                                <Route exact path="/customermanagement" render={() => <CustomerManagement />} />
                                <Route exact path="/customerdetails" render={() => <CustomerDetails />} />
                                <Route exact path="/shipmentmanagement" render={() => <ShipmentManagement />} />
                                {/* <Route exact path="/deliverymanagement" render={() => <DeliveryManagement />} /> */}
                                <Route exact path="/createdeliverymanagement" render={() => <CreateDeliveryManagement />} />
                                <Route exact path="/editdeliverymanagement" render={() => <EditDeliveryManagement />} />
                                <Route exact path="/ordermanagement" render={() => <OrderManagement />} />
                                <Route exact path="/returnmanagement" render={() => <ReturnManage />} />
                                <Route exact path="/detailreturnordermanagement" render={() => <AddEditReturn />} />
                                <Route exact path="/detailordermanagement" render={() => <AddEditOrderManagement />} />
                                <Route exact path="/inventorystatus" render={() => <InventoryStatus />} />
                                <Route exact path="/registeredusers" render={() => <CustomerMangement />} />
                                <Route exact path="/reviewsmanagement" render={() => <ReviewsManagement />} />
                                <Route exact path="/reviewdetail" render={() => <ReviewsDetail />} />
                                {/* <Route exact path="/dealmanagement" render={() => <ManageDeal />} /> */}
                                {/* <Route exact path="/productsupload" render={() => <UploadBulkProducts />} /> */}
                                <Route exact path="/feedback" render={() => <Feedback />} />
                                <Route exact path="/customercare" render={() => <CustomerCare />} />
                                <Route exact path="/deliverycharges" render={() => <DeliveryCharges />} />

                                {/* <Route exact path="/assignrole" render={() => <AssignRole />} /> */}
                            </Switch>
                        </div>
                    </div>
                </>
            )}
            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
