import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
import { Panel } from "primereact/panel";
import "./ImageUpload.css";

function MultiImage({ handleImages }) {

    const toastBC = React.useRef(null);
    const upload = React.useRef(null);
    const [files, setfiles] = useState([]);
    const [imgBase64, setImgBase64] = useState([]);
    const [loading, setloading] = useState(false);

    const header = () => {
        return (
            <>
                <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    ref={upload}
                    onChange={(e) => {
                        onFileChange(e);
                        upload.current.value = "";
                    }}
                />
                <Button
                    style={{ background: '#ff8b55', borderColor: '#ff8b55' }}
                    onClick={(e) => {
                        e.preventDefault();
                        upload.current.click();
                    }}
                    label="Choose File"
                ></Button>
                &nbsp;

                {imgBase64?.length ?
                    <Button
                        style={{ background: '#ff8b55', borderColor: '#ff8b55' }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClear();
                        }}
                        label="Clear"
                    ></Button>
                    :
                    null}
            </>
        );
    };

    const onFileChange = async (e) => {
        e.preventDefault();
        setloading(true);
        await getBase64(e.target.files[0]);
        setloading(false);
    };

    async function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (!files.some((file) => file?.fileBase64 === reader.result)) {
                let newfiles = JSON.parse(JSON.stringify(files));
                const filetype = file.type.split("/");
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    if (img.width < 350 || img.width > 400 || img.height < 350 || img.height > 400) {
                        toastBC.current.show({ severity: "error", summary: "Invalid Image", detail: "Image must be with dimensions between 350 and 400 pixels." });
                        return;
                    }
                    newfiles.push({ fileBase64: reader.result, fileName: file.name, fileSize: file.size, fileExtension: `.${filetype[1]}` });
                    setImgBase64((imgBase64) => [...imgBase64, reader.result]);
                    setfiles(newfiles);
                };
            }
        };
    }

    const handleRemove = (b64) => {
        let newArr = files.filter((file, index) => JSON.stringify(file?.fileBase64) !== JSON.stringify(b64));
        let Base64Array = imgBase64.filter((base64) => {
            return base64 !== b64;
        });
        setImgBase64(Base64Array);
        setfiles(newArr);
    };

    useEffect(() => {
    }, [files]);

    const handleClear = () => {
        setfiles([]);
        setImgBase64([]);
    };

    useEffect(() => {
        handleImages(imgBase64);
    }, [files]);

    return (
        <Panel header={header}>
            <Toast ref={toastBC} position="bottom-center" />

            <div className="formgrid grid ">
                {files.length ? (
                    files.map((file, i) => (
                        <React.Fragment key={i}>
                            <div className="field col-12 md:col-3" key={file?.fileBase64}>
                                {file?.fileExtension === ".png" || file?.fileExtension === ".jpeg" ? (
                                    <img src={file?.fileBase64} width="60px" alt="img" />
                                ) : (
                                    <Button
                                        icon={file?.fileExtension === ".pdf" ? "pi pi-file-pdf" : "pi pi-file-excel"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        tooltip={file?.fileExtension === ".pdf" ? "PDF file" : "EXCEL file"}
                                    />
                                )}
                            </div>

                            <div className="field col-12 md:col-5 mt-2">
                                <p>{file?.fileName}</p>
                            </div>

                            <div className="field col-12 md:col-3 mt-2">
                                <Badge value={(file?.fileSize / (1024 * 1024)).toFixed(2) + " MB"} />
                            </div>

                            <div className="field col-12 md:col-1">
                                <Button
                                    className="p-button-danger p-button-outlined p-button-sm"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleRemove(file?.fileBase64);
                                    }}
                                >
                                    <i className="pi pi-trash "></i>
                                </Button>
                            </div>
                        </React.Fragment>
                    ))
                ) : (
                    <center style={{ height: "3vh" }}>{/* <h6 className="image-placeholder">Please Upload Images</h6> */}</center>
                )}
                {loading && <h5>Loading</h5>}
            </div>
        </Panel>
    );
}

export default MultiImage;
