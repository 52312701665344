import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { handlePatchRequest } from "../../service/PatchTemplete";
import { handlePostRequest } from "../../service/PostTemplate";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../service/GetTemplate";
import { InputTextarea } from "primereact/inputtextarea";
import AddTaxType from "./AddTaxType";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Edit from "../../../src/assets/ICONS/icon_edit.png";
import Delete from "../../../src/assets/ICONS/icon_delete.png";
import { confirmDialog } from "primereact/confirmdialog";
import { handleDeleteRequest } from "../../service/DeleteTemplete";
import { ProgressSpinner } from "primereact/progressspinner";

const AddEdit = ({ addEditTax, getTaxpData, TaxRowData, onHide }) => {

    const [loading, setLoading] = useState(false);
    const [taxTypeData, setTaxTypeData] = useState();
    const [selectedTaxType, setSelectedTaxType] = useState("");
    const [taxHead, setTaxHead] = useState("");
    const [description, setDescription] = useState("");
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [addEditTax2, setaddEditTax2] = useState(false);
    const [taxRowData2, setTaxRowData2] = useState([]);

    useEffect(() => {
        getTaxList();
        if (addEditTax === true) {
            getHeadByID();
        }
    }, []);

    const getTaxList = async () => {
        const res = await handleGetRequest("api/v1/tax/type", false);
        if (res) {
            setTaxTypeData(res);
        }
    };

    const getHeadByID = async () => {
        setLoading(true)
        const res = await handleGetRequest(`api/v1/tax/head/getOne?taxHeadId=${TaxRowData}`, true);
        setTaxHead(res.taxHead);
        setSelectedTaxType(res.taxType._id);
        setDescription(res?.description);
        setLoading(false)
    };

    const buttonHandler = () => {
        setDisplayBasic(true);
    };

    const buttonHandler2 = () => {
        setDisplayBasic2(true);
    };

    const onHide2 = () => {
        setDisplayBasic(false);
    };

    const onHide3 = () => {
        setDisplayBasic2(false);
    };

    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        taxTypeId: Yup.mixed().required("Text type is required"),
        taxHead: Yup.mixed().required("Text head is required"),
        // description: Yup.mixed().required("Description is required"),
    });
    
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            taxTypeId: selectedTaxType,
            taxHead: taxHead,
            description: description,
        },
        enableReinitialize: true,
        onSubmit: async (data) => {
            setLoading(true);
            if (addEditTax === true) {

                data["taxHeadId"] = TaxRowData;
                const res = await dispatch(handlePatchRequest(data, "api/v1/tax/head", true, true));

                if (res.status === 200) {
                    await getTaxpData();

                    onHide();
                }
            } else {
                const res = await dispatch(handlePostRequest(data, "api/v1/tax/head", true, true));

                if (res?.status === 200 || res?.status === 201) {
                    await getTaxpData();
                    onHide();
                }
            }
            setLoading(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const actionTemplate2 = (rowData) => {
        return (
            <div className="Edit_Icon">
                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit p-mr-2" onClick={() => editTax(rowData)}>
                    <img src={Edit} />
                </Button>
                {/* <Button
                    tooltip="Delete"
                    tooltipOptions={{ position: "top" }}
                    className="delete p-mr-2 p-ml-3"
                    onClick={() => {
                        confirm3(rowData);
                    }}
                >
                    <img src={Delete} />
                </Button> */}
            </div>
        );
    };

    const editTax = (rowData) => {
        setDisplayBasic(true);
        setaddEditTax2(true);
        setTaxRowData2(rowData);
    };

    const confirm3 = (rowData) => {

        confirmDialog({
            message: "Are you sure you want to delete this tax type?",
            header: "Delete Confirmation",
            icon: "pi pi-trash",
            acceptClassName: "Savebtn",
            rejectClassName: "Cancelbtn",
            accept: () => deleteTax(rowData),
            reject: null,
        });
    };

    const deleteTax = async (rowData) => {

        console.log("rowData", rowData)
        const obj = {
            taxTypeId: rowData?._id
        }

        const res = await dispatch(handleDeleteRequest(obj, `api/v1/tax/type/`, true, true));
        if (res?.status == 200) {
            getTaxList();
        }
    };

    return (
        <div>
            <Dialog header="Add New Tax Type" visible={displayBasic} style={{ width: "40vw" }} onHide={onHide2}>
                <AddTaxType taxRowData2={taxRowData2} addEditTax2={addEditTax2} onHide={onHide2} getTaxList={getTaxList} />
            </Dialog>

            <Dialog header="All Tax Type" visible={displayBasic2} style={{ width: "40vw" }} onHide={onHide3}>
                <div className="grid">
                    <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                        {/* <div className="text-right">
                            <button type="button"  className="p-button p-button-primary p-component ml-2 mt-4" onClick={() => buttonHandler()}>
                                <span className="p-button-icon p-c p-button-icon-left pi pi-plus"></span>
                                <span className="p-button-label p-c">Add Tax Type</span>
                                <span className="p-ink"></span>
                            </button>
                        </div> */}
                    </div>
                    <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                        <div className="innr-Body">
                            <DataTable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                rows={5}
                                paginator
                                responsiveLayout="scroll"
                                value={taxTypeData}
                            >
                                <Column field="taxType" header="Tax Type " />
                                <Column body={actionTemplate2} header="Action" />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid p-p-3">
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Tax Type <span style={{ color: "red" }}>*</span>
                                </label>
                                <Dropdown disabled placeholder="--Please Select--" name="taxTypeId" id="taxTypeId" className={classNames({ "p-invalid": isFormFieldValid("taxTypeId") }, "w-full md:w-10 inputClass")} value={formik.values.taxTypeId} options={taxTypeData} optionLabel="taxType" optionValue="_id" onChange={formik.handleChange} />
                                {getFormErrorMessage("taxTypeId")}
                            </div>
                            {/* <div className="col-4">
                            <button type="button" className="p-button p-button-primary p-component ml-2 mt-4" onClick={() => buttonHandler2()}>
                                <span className="p-button-label p-c">Manage Tax</span>
                                <span className="p-ink"></span>
                            </button>
                        </div> */}
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Tax Percentage <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText name="taxHead" keyfilter={/^[0-9.!@#$%^&*+]+$/} maxLength={6} id="taxHead" type="text" className={classNames({ "p-invalid": isFormFieldValid("taxHead") }, "w-full md:w-10 inputClass")} value={formik.values.taxHead} onChange={formik.handleChange} />
                                {getFormErrorMessage("taxHead")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Description 
                                </label>
                                <InputTextarea name="description" rows={5} cols={30} maxLength={250} id="description" type="text" className={classNames({ "p-invalid": isFormFieldValid("description") }, "w-full md:w-10 inputClass")} value={formik.values.description} onChange={formik.handleChange} />
                                {getFormErrorMessage("description")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                            <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                            <Button autoFocus className="Savebtn" label={addEditTax ? "Update" : "Save"} type="submit" loading={loading} />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AddEdit;
