import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import * as Yup from "yup";
import AddEditImage from "../../../components/AddEditImage";
import { handleGetRequest } from "../../../service/GetTemplate";
import { handlePostRequest } from "../../../service/PostTemplate";
import { handlePatchRequest } from "../../../service/PatchTemplete";
import { useDispatch } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputTextarea } from "primereact/inputtextarea";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";

const AddEditCategory = ({ getCategoryData, onHide, editable, categoryRowData }) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [iconFileData, setIconFileData] = useState("");
    const [thumbnailFileData, setThumbnailFileData] = useState("");
    const onlyalphabetSRegex = /[a-z\d\-_\s]+/i;

    const getUsersByID = async () => {

        const data = {};
        data["roleId"] = categoryRowData;
        setLoading(true);
        const res = await handleGetRequest(`api/v1/category/getOne?categoryId=${categoryRowData}`, true);
        setLoading(false);
        console.log("res", res)
        if (res) {
            const keyData = res;
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('status', res?.isActive)
        }
    };

    useEffect(() => {
        if (categoryRowData !== undefined && categoryRowData !== null && editable === true) {
            getUsersByID();
        }
    }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string()?.required("This field is required.")?.matches(onlyalphabetSRegex, "This field should contain alphabets only"),
        // description: Yup.string().required("This field is required.").max(150, "Description must be at most 150 characters").nullable(),
        icon: Yup.string().required("This field is required."),
        thumbnail: Yup.string().required("This field is required."),
       // isActive: Yup.string().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            description: "",
            name: "",
            icon: "",
            thumbnail: "",
            isActive: "",
        },

        onSubmit: async (data, { setErrors }) => {
            setLoading(true);
            if (editable === true) {
                if (iconFileData === "" && data.icon === "") {
                    toast.error("Icon field is required.");
                    return;
                }
                if (thumbnailFileData === "" && data.thumbnail === "") {
                    toast.error("Thumbnail field is required.");
                    return;
                }
            } else {
                if (iconFileData === "") {
                    toast.error("Icon field is required.");
                    return;
                }
                if (thumbnailFileData === "") {
                    toast.error("Thumbnail field is required.");
                    return;
                }
            }

            if (Object.keys(formik.errors).length === 0) {

                if (editable === true) {

                    onHide();
                    data["categoryId"] = categoryRowData;
                    data["icon"] = iconFileData;
                    data["thumbnail"] = thumbnailFileData;

                    const res = await dispatch(handlePatchRequest(data, "api/v1/category/", true, true));

                    if (res?.status === 200) {
                        await getCategoryData();

                        formik.resetForm();
                        onHide();
                    }
                } else {
                    data["icon"] = iconFileData;
                    data["thumbnail"] = thumbnailFileData;
                    data["categoryId"] = categoryRowData;
                    const res = await dispatch(handlePostRequest(data, "api/v1/category/", true, true));
                    if (res?.status === 200) {
                        await getCategoryData();
                        formik.resetForm();

                        onHide();
                    }
                }
            }
            setLoading(false);
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onHide();
    };

    const handleIconImages = (images) => {
        formik.setFieldValue("icon", images);
        setIconFileData(images);
    };

    const handleThumbnailImages = (images) => {
        formik.setFieldValue("thumbnail", images);
        setThumbnailFileData(images);
    };

    const status = [
        { name: 'Active', value: true },
        { name: 'InActive', value: false },
    ]

    return (
        <>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid p-p-3">
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Category Name <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    maxLength={35}
                                    placeholder="Enter Category Name"
                                    id="name"
                                    name="name"
                                    value={formik?.values?.name?.replace(/\s\s+/g, " ")}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const regex = /^[a-zA-Z\s]*$/;
                                        if (regex.test(input)) {
                                            formik.handleChange(e);
                                        }
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                        }
                                    }}
                                    className={classNames({ "p-invalid": isFormFieldValid("name") }, "w-full md:w-10 inputClass")}
                                />
                                {getFormErrorMessage("name")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Main Image <span style={{ color: "red" }}>*</span>
                                </label>
                                <AddEditImage text={iconFileData || formik?.values.icon ? "Update Image" : "Upload Image"} handleImages={handleIconImages} editable={editable} EditIconImage={formik?.values.icon} />
                                {getFormErrorMessage("icon")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Thumbnail <span style={{ color: "red" }}>*</span>
                                </label>
                                <AddEditImage text={thumbnailFileData || formik?.values.icon ? "Update Image" : "Upload Image"} handleImages={handleThumbnailImages} editable={editable} EditIconImage={formik?.values.thumbnail} />
                                {getFormErrorMessage("thumbnail")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Description
                                </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    placeholder="Enter Description"
                                    id="description"
                                    name="description"
                                    maxLength={150}
                                    value={formik?.values?.description?.replace(/\s\s+/g, " ")}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("description") }, "w-full md:w-10 inputClass")}
                                />
                                {getFormErrorMessage("description")}
                            </div>
                        </div>

                        {editable ?
                            <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Status <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Dropdown
                                        placeholder="--Please Select--"
                                        id="isActive"
                                        name="isActive"
                                        value={formik?.values?.isActive}
                                        onChange={formik.handleChange}
                                        className="w-full md:w-10 inputClass"
                                        options={status}
                                        optionLabel="name"
                                        optionValue="value"
                                    />
                                    {getFormErrorMessage("isActive")}
                                </div>
                            </div>
                            :
                            null
                        }


                        <div className="col-12 text-center">
                            <Button label="Cancel" onClick={(e) => handleCancel(e)} className="Cancelbtn p-mr-3" />
                            <Button loading={loading} type="submit" disabled={loading} iconPos="right" label={editable ? "Update" : "Save"} autoFocus className="Savebtn p-mr-3" />
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default AddEditCategory;
