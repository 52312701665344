import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import AddEdit from "./AddEdit";
import { handleGetRequest } from "../../service/GetTemplate";
import { handleDeleteRequest } from "../../service/DeleteTemplete";
import { useDispatch } from "react-redux";
import Edit from "../../../src/assets/ICONS/icon_edit.png";
import Delete from "../../../src/assets/ICONS/icon_delete.png";

const ManageTax = () => {
    
    const dispatch = useDispatch();
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic3, setDisplayBasic3] = useState(false);
    const [taxdata, setTaxdata] = useState([]);
    const [TaxRowData, setTaxRowData] = useState(null);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [addEditTax, setaddEditTax] = useState(null);
    const [selectedDeleteId, setSelectedDeleteId] = useState("");
    const [permissions, setPermissions] = useState([]);

    const dialogFuncMap = {
        displayBasic: setDisplayBasic,
        displayBasic3: setDisplayBasic3,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const getTaxData = async () => {
        const res = await handleGetRequest("api/v1/tax/head", false);
        if (res) {
            setTaxdata(res);
        }
    };
    useEffect(() => {
        getTaxData();
    }, []);

    const TaxHeadDelete = async () => {
        const data = {};
        data["taxHeadId"] = selectedDeleteId;
        const res = await dispatch(handleDeleteRequest(data, `api/v1/tax/head`, true, true));
        if (res.status === 200) {
            getTaxData();
        }
        setSelectedDeleteId("");
        setVisibleDelete(false);
    };
    useEffect(() => {
        if (visibleDelete === true && selectedDeleteId !== "") {
            TaxHeadDelete();
        }
    }, [visibleDelete]);

    const onHide = () => {
        setDisplayBasic(false);
        setDisplayBasic3(false);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit p-mr-2" onClick={() => editUsers(rowData)}>
                    <img src={Edit} />
                </Button>
                {/* <Button
                    tooltip="Delete"
                    tooltipOptions={{ position: "top" }}
                    className="delete p-mr-2 p-ml-3"
                    onClick={() => {
                        confirm2(rowData);
                    }}
                >
                    <img src={Delete} />
                </Button> */}
            </div>
        );
    };

    const editUsers = (rowData) => {
        setDisplayBasic(true);
        setaddEditTax(true);
        setTaxRowData(rowData._id);
    };

    const confirm2 = (rowData) => {
        setSelectedDeleteId(rowData._id);
        confirmDialog({
            message: "Are you sure you want to delete this tax type?",
            header: "Delete Confirmation",
            icon: "pi pi-trash",
            acceptClassName: "Savebtn",
            rejectClassName: "Cancelbtn",
            accept,
            reject,
        });
    };
    const accept = () => {
        setVisibleDelete(true);
    };

    const reject = () => {
        setVisibleDelete(false);
    };
    
    const toast = useRef(null);
    const TaxTypeTemplate = (rowData) => {
        return <div>{rowData?.taxType?.taxType}</div>;
    };


    const taxPercentage = (rowData) => {
        return <div>{rowData?.taxHead}%</div>;
    };

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const taxesManagementModule = myPermissions.modules.find(module => module.module.route.includes("/taxesmanagement"));
        if (taxesManagementModule) {
            setPermissions(taxesManagementModule?.permissions?.map(item => item?.name) || []);
        }
    }, []);

    return (
        <>
            <Toast ref={toast} />
            <Dialog header={addEditTax ? "Edit" : "Add New Tax"} visible={displayBasic} style={{ width: "40vw" }} onHide={onHide}>
                <AddEdit onHide={onHide} getTaxpData={getTaxData} addEditTax={addEditTax} TaxRowData={TaxRowData} />
            </Dialog>

            <div className="grid">
                <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                    <div className="text-right">
                        <span class="p-input-icon-right mr-3">
                            <input type="text" placeholder="Search" class="p-inputtext p-component p-filled" onInput={(e) => setGlobalFilter(e.target.value)} />
                            <i class="pi pi-search"></i>
                        </span>
                        {/* {permissions?.includes("create") ?
                            <button
                                className="p-button p1-button-primary p-component mr-2"
                                onClick={() => {
                                    setaddEditTax(false);
                                    onClick("displayBasic");
                                }}
                            >
                                <span className="p-button-icon p-c p-button-icon-left pi pi-plus"></span>
                                <span className="p-button-label p-c">Add New Tax Head</span>
                                <span className="p-ink"></span>
                            </button> : null
                        } */}

                    </div>
                </div>
                <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                    <div className="innr-Body">
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            globalFilter={globalFilter}
                            rows={7}
                            paginator
                            responsiveLayout="scroll"
                            value={taxdata}
                            globalFilterFields={["taxHead", "description", "taxType.taxType"]}
                        >
                            <Column field="taxHead" body={taxPercentage} header="Tax Percentage" />
                            <Column field="taxType.taxType" body={TaxTypeTemplate} header="Tax Type" />
                            <Column field="description" header="Description" style={{ width: "250px", height: "57px" }} />
                            {permissions.includes("manage") ?
                                <Column body={actionTemplate} header="Action" />
                                :
                                null
                            }
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageTax;
