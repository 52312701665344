import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../service/PostTemplate";
import { handleGetRequest } from "../../service/GetTemplate";
import { Divider } from 'primereact/divider'

const AssignRole2 = (getPremissionData) => {

    const dispatch = useDispatch();
    const [userRoles, setUserRoles] = useState([]);
    const [permissions, setPermissions] = useState();
    const [rolePermissions, setRolePermissions] = useState([]);
    const [formDisable, setFormDisable] = useState(false);
    const [loadingIcon, setLoadingIcon] = useState('');

    const [selectedModules, setSelectedModules] = useState([]);
    const [selectedSubmodules, setSelectedSubmodules] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState({});

    console.log("selectedModules", selectedModules)
    console.log("selectedSubmodules", selectedSubmodules)
    console.log("selectedPermissions", selectedPermissions)

    const validationSchema = Yup.object().shape({
        roleId: Yup.string()?.required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            roleId: "",
            history: "",
            modules: [
                {
                    module: "",
                    isSubmodule: true,
                    permissions: "",
                },
            ],
        },

        enableReinitialize: true,

        onSubmit: async (data) => {
            const result = [];
            setLoadingIcon("pi pi-spin pi-spinner");

            // Iterate through selected modules
            selectedModules.forEach((moduleId) => {
                const module = rolePermissions.find((mod) => mod._id === moduleId);

                if (module) {
                    const moduleObject = {
                        module: module._id,
                        isSubmodule: module.isSubModule,
                        permissions: [],
                        subModules: [],
                    };

                    // If submodule, add permissions directly
                    if (module.isSubModule) {
                        moduleObject.permissions = module.permissions
                            .filter((permission) => selectedPermissions[module._id]?.[permission._id])
                            .map((permission) => permission._id);

                        // If it has submodules, iterate through them
                        if (module.sub_modules && module.sub_modules.length > 0) {
                            moduleObject.subModules = module.sub_modules
                                .filter((submoduleId) => selectedSubmodules.includes(submoduleId._id))
                                .map((submodule) => ({
                                    subModule: submodule._id,
                                    permissions: submodule.permissions
                                        .filter((permission) => selectedPermissions[submodule._id]?.[permission._id])
                                        .map((permission) => permission._id),
                                }));
                        }
                    } else {
                        // If it's not a submodule, add all permissions
                        moduleObject.permissions = module.permissions
                            .filter((permission) => selectedPermissions[module._id]?.[permission._id])
                            .map((permission) => permission._id);
                    }
                    console.log("moduleObject", moduleObject)
                    result.push(moduleObject);
                }
            });

            console.log("result", result)

            const body = {
                role: formik.values.roleId,
                history: "History",
                modules: result,
            };

            console.log("body", body)
            const res = await dispatch(handlePostRequest(body, "api/v1/rolePermission/", true, true));

            setLoadingIcon("");
            if (res?.status === 201 || res?.status === 200) {
                // setSelectedModules([]);
                // setSelectedSubmodules([]);
                // setSelectedPermissions({});
                setLoadingIcon("");
                await getPremissionData();
            }
            setLoadingIcon("");
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getUsersRole = async () => {
        const res = await handleGetRequest("api/v1/role/all", false);
        setUserRoles(res);
    };

    const getModule = async () => {
        const res = await handleGetRequest("api/v1/module/all", false);

        const filteredModules = res?.map(module => {
            
            if (module.label === "Taxes Management") {
                const filteredPermissions = module.permissions.filter(permission => permission.name !== "create");
                return {
                    ...module,
                    permissions: filteredPermissions
                };
            } 
            if (module.label === "Delivery Charges") {
                const filteredPermissions = module.permissions.filter(permission => permission.name !== "create");
                return {
                    ...module,
                    permissions: filteredPermissions
                };
            } 
            else {
                return module;
            }
        });

        console.log("filteredModules", filteredModules);
        setRolePermissions(filteredModules);
    };

    useEffect(() => {
        getUsersRole();
        getModule();
    }, []);

    const getPermissionData = async (roleId) => {
        try {
            const res = await handleGetRequest(`api/v1/rolePermission/getByRole?roleId=${roleId}`, true);

            if (res?.modules) {
                const newSelectedModules = [];
                const newSelectedSubmodules = [];
                const newSelectedPermissions = {};

                res.modules.forEach((module) => {
                    // Handle Main Modules
                    newSelectedModules.push(module.module?._id);

                    // Handle Submodules
                    if (module.module.isSubModule) {
                        newSelectedSubmodules.push(module.module?._id);
                    }

                    // Handle Permissions for Main Module
                    if (!module.module.isSubModule && module.permissions && module.permissions.length > 0) {
                        newSelectedPermissions[module.module?._id] = {};
                        module.permissions.forEach((permission) => {
                            newSelectedPermissions[module.module?._id][permission?._id] = true;
                        });
                    }

                    // Handle Submodules and their Permissions
                    module.subModules.forEach((subModule) => {
                        newSelectedSubmodules.push(subModule.subModule?._id);

                        if (!newSelectedPermissions[subModule.subModule?._id]) {
                            newSelectedPermissions[subModule.subModule?._id] = {};
                        }

                        // Handle Permissions for Submodule
                        if (subModule.permissions && subModule.permissions.length > 0) {
                            const subModuleId = subModule.subModule?._id;

                            if (!newSelectedPermissions[subModuleId]) {
                                newSelectedPermissions[subModuleId] = {};
                            }

                            // Check if there are permissions for the submodule
                            subModule.permissions.forEach((permission) => {
                                // Assign true to the specific key inside the object
                                newSelectedPermissions[subModuleId][permission?._id] = true;
                            });
                        }

                    });
                });


                setSelectedModules(newSelectedModules);
                setSelectedSubmodules(newSelectedSubmodules);
                setSelectedPermissions(newSelectedPermissions);
            } else {

                setSelectedModules([]);
                setSelectedSubmodules([]);
                setSelectedPermissions({});
            }
        } catch (error) {
            // Handle error, log or display a message
            console.error("Error fetching permission data:", error);
        }
    };

    useEffect(() => {
        if (formik.values.roleId) {
            getPermissionData(formik.values.roleId);
        }
    }, [formik.values.roleId]);


   const handleModuleChange = (moduleId, isSubModule) => {
        setSelectedModules((prevModules) =>
            prevModules.includes(moduleId)
                ? prevModules.filter((id) => id !== moduleId)
                : [...prevModules, moduleId]
        );

        // Check if the main module ID is not in selectedModules
        const isMainModuleSelected = selectedModules.includes(moduleId);

        // Remove submodule IDs associated with the main module if it's not selected
        if (!isMainModuleSelected) {
            const module = rolePermissions.find((mod) => mod._id.includes(moduleId));
            if (module) {
                module.sub_modules.forEach((subModule) => {
                    const subModuleId = subModule._id;
                    if (selectedSubmodules.includes(subModuleId)) {
                        setSelectedSubmodules((prevSubmodules) =>
                            prevSubmodules.filter((id) => id !== subModuleId)
                        );
                        // Remove permissions for the submodule
                        setSelectedPermissions((prevPermissions) => {
                            const updatedPermissions = { ...prevPermissions };
                            delete updatedPermissions[subModuleId];
                            return updatedPermissions;
                        });
                    }
                });
            }
        }
        else {
            const mainModuleSelected = selectedModules.includes(moduleId);
            if (mainModuleSelected) {
                const module = rolePermissions.find((mod) => mod._id.includes(moduleId));
                const removeIds = module?.sub_modules?.map((item) => item?._id);
                if (removeIds) {
                    // Remove all associated submodule ids from selectedSubmodules array
                    const removeAssociatedIds = selectedSubmodules.filter((id) => !removeIds.includes(id))

                    setSelectedSubmodules(removeAssociatedIds)
                    return
                    // Remove permissions for the removed submodules
                    setSelectedPermissions((prevPermissions) => {
                        const updatedPermissions = { ...prevPermissions };
                        removeIds.forEach((subModuleId) => {
                            delete updatedPermissions[subModuleId];
                        });
                        return updatedPermissions;
                    });
                }
            }
        }

        // Rest of the function remains the same
        const module = rolePermissions.find((mod) => mod._id === moduleId);
        if (module) {
            const newSelectedSubmodules = [...selectedSubmodules];
            const newSelectedPermissions = { ...selectedPermissions };

            // Handle Submodules and their Permissions
            module.sub_modules.forEach((subModule) => {
                const subModuleId = subModule._id;
                if (!newSelectedSubmodules.includes(subModuleId)) {
                    newSelectedSubmodules.push(subModuleId);
                }

                if (!newSelectedPermissions[subModuleId]) {
                    newSelectedPermissions[subModuleId] = {};
                }

                if (subModule.permissions && subModule.permissions.length > 0) {
                    if (!newSelectedPermissions[subModuleId]) {
                        newSelectedPermissions[subModuleId] = {};
                    }

                    // Check if there are permissions for the submodules
                    subModule.permissions.forEach((permission) => {
                        // Assign true to the specific key inside the object
                        newSelectedPermissions[subModuleId][permission._id] = true;
                    });
                }
            });

            // Handle Permissions for the selected main module
            if (!isSubModule && module.permissions && module.permissions.length > 0) {
                if (!newSelectedPermissions[moduleId]) {
                    newSelectedPermissions[moduleId] = {};
                }

                module.permissions.forEach((permission) => {
                    newSelectedPermissions[moduleId][permission._id] = true;
                });
            }

            setSelectedSubmodules(newSelectedSubmodules);
            setSelectedPermissions(newSelectedPermissions);
        }
    };



    // Function to handle submodule selection
    const handleSubmoduleChange = (submoduleId) => {
        setSelectedSubmodules((prev) =>
            prev.includes(submoduleId) ? prev.filter((id) => id !== submoduleId) : [...prev, submoduleId]
        );
    };

    // Function to handle permission selection
    const handlePermissionChange = (moduleId, permissionId) => {
        setSelectedPermissions((prev) => ({
            ...prev,
            [moduleId]: {
                ...prev[moduleId],
                [permissionId]: !prev[moduleId]?.[permissionId],
            },
        }));
    }

    const handleSelectAll = () => {
        setFormDisable(false)
        if (rolePermissions) {
            const newSelectedModules = [];
            const newSelectedSubmodules = [];
            const newSelectedPermissions = {};

            rolePermissions.forEach((module) => {
                // Handle Main Modules
                newSelectedModules.push(module._id);

                // Handle Submodules
                if (module.isSubModule) {
                    newSelectedSubmodules.push(module._id);
                }

                // Handle Permissions for Main Module
                if (!module.isSubModule && module.permissions && module.permissions.length > 0) {
                    newSelectedPermissions[module?._id] = {};
                    module.permissions.forEach((permission) => {
                        newSelectedPermissions[module?._id][permission?._id] = true;
                    });
                }

                // Handle Submodules and their Permissions
                module.sub_modules.forEach((subModule) => {
                    newSelectedSubmodules.push(subModule?._id);

                    if (!newSelectedPermissions[subModule?._id]) {
                        newSelectedPermissions[subModule?._id] = {};
                    }

                    // Handle Permissions for Submoduless
                    if (subModule.permissions && subModule.permissions.length > 0) {
                        const subModuleId = subModule?._id;

                        if (!newSelectedPermissions[subModuleId]) {
                            newSelectedPermissions[subModuleId] = {};
                        }

                        // Check if there are permissions for the submodulessss
                        subModule.permissions.forEach((permission) => {
                            // Assign true to the specific key inside the objecttttt
                            newSelectedPermissions[subModuleId][permission?._id] = true;
                        });
                    }
                });
            });

            // Set the selected modules, submodules, and permissions
            setSelectedModules(newSelectedModules);
            setSelectedSubmodules(newSelectedSubmodules);
            setSelectedPermissions(newSelectedPermissions);
        }
    };

    const handleDeSelectAll = () => {
        setFormDisable(false)
        setSelectedModules([]);
        setSelectedSubmodules([]);
        setSelectedPermissions([]);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: formDisable ? 'none' : 'auto', // Disable pointer events when formDisabled is true
        opacity: formDisable ? 0.6 : 1, // Dim the form when formDisabled is true
    };

    useEffect(() => {

        const response = localStorage.getItem("permissions")
        const myPermissions = JSON.parse(response)
        const allPermModules = myPermissions?.modules?.find(module => module?.module?.route?.includes("/usermanagement"));
        if (allPermModules) {
            const result = allPermModules?.subModules?.filter(item => item?.subModule?.route == "/createrolepermission")
            const result2 = result[0]?.permissions && result[0]?.permissions.map(item => item.name)
            setPermissions(result2 || []);
        }
    }, []);

    return (

        <form onSubmit={formik.handleSubmit}>
            <div className="card">
                <h3>Select User Role and Assign Permissions </h3>
                <hr />
                <div className="mt-5">
                    <div className="p-fluid formgrid grid">
                        <div className="col-4 md:col-4 lg:col-4 xl:col-4">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Role <span style={{ color: "red" }}>*</span>
                                </label>
                                <Dropdown
                                    placeholder="--Please Select--"
                                    filter
                                    filterBy="name"
                                    id="roleId"
                                    options={userRoles}
                                    optionLabel="name"
                                    name="roleId"
                                    optionValue="_id"
                                    value={formik.values.roleId}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("roleId") }, "w-full md:w-10 inputClass")}
                                />
                                {getFormErrorMessage("roleId")}
                            </div>
                        </div>
                    </div>
                </div>

                <Divider type="dashed" />
                <div style={{ overflowX: 'auto' }}>
                    <Button disabled={rolePermissions?.length === 0 ? true : false} style={{ float: "right" }} type="button" label="Select All" aria-label="Select All" className="Savebtn mb-3 mt-0 float-right" onClick={handleSelectAll} />
                    <Button disabled={selectedModules?.length === 0 ? true : false} style={{ float: "right" }} type="button" label="Deselect All" aria-label="Deselect All" className="Deletebtn mb-3 mr-2 mt-0 float-right" onClick={handleDeSelectAll} />
                    <table className='col-12 card datatable-responsive pl-0 pr-0' style={formStyle}>
                        <thead className='card' style={{ height: "40px", backgroundColor: "#ff8b55", color: "white", padding: "10px 2px" }}>
                            <tr>
                                <th className="customTable-header-resp" style={{ fontSize: '18px', width: '33.33%', textAlign: "left", padding: "2px 30px" }}>Module</th>
                                <th className="customTable-header-resp" style={{ fontSize: '18px', width: '33.33%', textAlign: "left", padding: "2px 30px" }}>Submodules</th>
                                <th className="customTable-header-resp" style={{ fontSize: '18px', width: '33.33%', textAlign: "left", padding: "2px 30px" }}>Permissions</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#efefef", color: "black" }}>
                            {rolePermissions?.map((module) => (
                                <tr key={module._id}>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '12px 25px', textAlign: 'left' }}>
                                        <label className="custom-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={selectedModules.includes(module._id)}
                                                onChange={() => handleModuleChange(module._id, module.isSubModule)}
                                            />
                                            <span className="checkmark">
                                                <span className="checkmark-icon">✔</span>
                                            </span>
                                            {module.label}
                                        </label>
                                    </td>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '16px 25px', textAlign: 'left' }}>
                                        {module.isSubModule && selectedModules.includes(module._id) && (
                                            module.sub_modules.map((submodule) => (
                                                <div key={submodule._id}>
                                                    <label className="custom-checkbox mb-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedSubmodules.includes(submodule._id)}
                                                            onChange={() => handleSubmoduleChange(submodule._id)}
                                                        />
                                                        <span className="checkmark">
                                                            <span className="checkmark-icon">✔</span>
                                                        </span>
                                                        {submodule.label}
                                                    </label>
                                                </div>
                                            ))
                                        )}
                                    </td>
                                    <td className="table-center" style={{ fontSize: '16px', padding: '12px 25px', textAlign: 'left' }}>
                                        {!module.isSubModule && selectedModules.includes(module._id) ? (
                                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                {module.permissions.map((permission) => (
                                                    <label key={permission._id} style={{ marginRight: "10px" }} className="custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedPermissions[module._id]?.[permission._id]}
                                                            onChange={() => handlePermissionChange(module._id, permission._id)}
                                                        />
                                                        <span className="checkmark">
                                                            <span className="checkmark-icon">✔</span>
                                                        </span>
                                                        {capitalizeFirstLetter(permission.name)}
                                                    </label>
                                                ))}
                                            </div>
                                        ) : (
                                            // Display submodules and their permissions for selected main module
                                            module.sub_modules
                                                .filter((submodule) => selectedSubmodules.includes(submodule._id))
                                                .map((submodule, index, array) => (
                                                    <React.Fragment key={submodule._id}>
                                                        <div className="moduleContainer" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                            {submodule.permissions.map((permission) => (
                                                                <React.Fragment key={permission._id}>
                                                                    <label style={{ marginRight: "10px" }} className="custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedPermissions[submodule._id]?.[permission._id]}
                                                                            onChange={() => handlePermissionChange(submodule._id, permission._id)}
                                                                        />
                                                                        <span className="checkmark">
                                                                            <span className="checkmark-icon">✔</span>
                                                                        </span>
                                                                        {capitalizeFirstLetter(permission.name)}
                                                                    </label>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            {index < array.length - 1 && <hr style={{ borderTop: "1px solid #b6b6b6", width: "80%", margin: "0.5rem 2.7rem" }} />}
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div align="center" className="col-12 text-center">
                    {permissions?.includes("create") || permissions?.includes('manage') ?
                        <Button disabled={formik.values.roleId == "" ? true : false} type="submit" icon={loadingIcon || ""} iconPos="right" label="Save" aria-label="Save" className="Savebtn" />
                        : null
                    }
                </div>
            </div>
        </form>
    );
};

export default AssignRole2;