import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
// import { useHistory } from "react-router-dom";
// import { Image } from 'primereact/image';
import { useFormik } from "formik";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
// import { MultiSelect } from "primereact/multiselect";
// import { InputMask } from "primereact/inputmask";
import * as Yup from "yup";
//  import ImageUpload from "../../../components/ImageUpload/index"
import AddEditImage from "../../../components/AddEditImage";
import { handleGetRequest } from "../../../service/GetTemplate";
import { handlePostRequest } from "../../../service/PostTemplate";
import { handlePatchRequest } from "../../../service/PatchTemplete";
import { useDispatch } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
//import { config } from "react-transition-group";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import MultiImage from "../../../components/MultiImage";

const AddEditSubCategory = ({ updatedData, getSubcategorydata, onHide, subEditable, subCatRowData }) => {
    const [loading, setLoading] = useState(false);
    const [fileUploadData, setfileUploadData] = useState("");
    const [fileThumbnail, setFileThumbnail] = useState("");
    // const [loadingIcon, setloadingIcon] = useState("pi pi-save");
    const [category, setCategory] = useState([]);
    // const [userId, setUserId] = useState();

    // const aplhaNumericSRegex = /^[0-9a-zA-Z]*$/;
    // /[a-z\d\-_\s]+/i;

    // useEffect(() => { console.log('sId here', subCatRowData) }, [])
    // const history = useHistory();
    const dispatch = useDispatch();

    const getSubCategoryByID = async () => {
        setLoading(true);
        const res = await handleGetRequest(`api/v1/subcategory/getOne?subcategoryId=${subCatRowData}`, true);
        setLoading(false);
        if (res) {
            formik.setFieldValue("icon", res.icon);
            formik.setFieldValue("thumbnail", res.thumbnail);
            formik.setFieldValue("description", res.description);
            formik.setFieldValue("name", res.name);
        }
    };

    useEffect(() => {
        if (getSubcategorydata !== undefined && getSubcategorydata !== null && subEditable === true) {
            console.log('subCatRowData', subCatRowData)
            formik.setFieldValue("categoryId", subCatRowData?.category?.name);
            formik.setFieldValue("icon", subCatRowData.icon);
            formik.setFieldValue("thumbnail", subCatRowData.thumbnail);
            formik.setFieldValue("description", subCatRowData.description);
            formik.setFieldValue("name", subCatRowData.name);
            formik.setFieldValue('isActive', subCatRowData?.isActive)
        }
    }, []);

    const getCategoryLOV = async () => {
        const res = await handleGetRequest("api/v1/category/all", false);
        if (res) {
            setCategory(res);
        }
    };
    useEffect(() => {
        getCategoryLOV();
    }, []);

    console.log("updatedData", updatedData)

    const validationSchema = Yup.object().shape({
        categoryId: Yup.string().required("This field is required."),
        name: Yup.string().required("This field is required."),
        // .matches(aplhaNumericSRegex, "Only alphabat and numeric"),
        //description: Yup.string().required("This field is required.").nullable(),
        icon: Yup.string().required("This field is required"),
        thumbnail: Yup.string().required("This field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            description: "",
            name: "",
            categoryId: updatedData?.name,
            icon: "",
            thumbnail: "",
            isActive: "",
            // permissionsId: "",
        },

        onSubmit: async (data) => {
            // return
            setLoading(true);
            if (subEditable === true) {
                data["icon"] = fileUploadData;
                data["thumbnail"] = fileThumbnail;
                data["subcategoryId"] = subCatRowData;
                data["categoryId"] = subCatRowData?.category?._id;

                const res = await dispatch(handlePatchRequest(data, "api/v1/subcategory/", true, true));
                if (res?.status === 200) {
                    await getSubcategorydata();
                    formik.resetForm();
                    onHide();
                }
            } else {
                data["icon"] = fileUploadData;
                data["thumbnail"] = fileThumbnail;
                data["subcategoryId"] = subCatRowData;
                data["categoryId"] = updatedData?._id;

                const res = await dispatch(handlePostRequest(data, "api/v1/subcategory/", true, true));
                // toast.configure();
                if (res?.status === 200) {
                    await getSubcategorydata();
                    formik.resetForm();
                    onHide();
                }
                else {
                    formik.setFieldValue('categoryId', updatedData?.name)
                }
            }
            setLoading(false);
            // setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = (e) => {
        e.preventDefault();
        onHide();
    };

    //Callback Function to Get Base64 of Uploaded Image
    const handleImages = (images) => {
        formik.setFieldValue("icon", images);
        setfileUploadData(images);
    };
    const handleThumbnail = (images) => {
        formik.setFieldValue("thumbnail", images);
        setFileThumbnail(images);
    };

    const handleSubmit = (e) => {
        e.onSubmit();
        onHide();
    };

    const status = [
        { name: 'Active', value: true },
        { name: 'InActive', value: false },
    ]


    return (
        <>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid p-p-3">
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">Category <span style={{ color: "red" }}>*</span></label>
                                <InputText disabled id="categoryId" name="categoryId" className={classNames({ "p-invalid": isFormFieldValid("categoryId") }, "w-full md:w-10 inputClass")} value={formik.values.categoryId} options={category} onChange={formik.handleChange} />
                                {getFormErrorMessage("categoryId")}
                            </div>
                        </div>

                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">Sub-Category <span style={{ color: "red" }}>*</span></label>
                                <InputText maxLength={35} placeholder="Enter Sub-Category" id="name" name="name" value={formik?.values?.name?.replace(/\s\s+/g, " ")} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "w-full md:w-10 inputClass")} />
                                {getFormErrorMessage("name")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">Main Image <span style={{ color: "red" }}>*</span> </label>
                                <AddEditImage text={formik?.values.icon || fileUploadData ? "Update Image" : "Upload Image"} handleImages={handleImages} editable={subEditable} EditIconImage={formik?.values.icon} />
                                {getFormErrorMessage("icon")}
                                {/* <ImageUpload handleImages={handleImages} className="w-full md:w-10 inputClass"/> */}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">Thumbnail <span style={{ color: "red" }}>*</span> </label>
                                <AddEditImage text={formik?.values.thumbnail || fileThumbnail ? "Update Image" : "Upload Image"} handleImages={handleThumbnail} editable={subEditable} EditIconImage={formik?.values.thumbnail} />
                                {getFormErrorMessage("thumbnail")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">Description </label>
                                <InputTextarea
                                    rows={5}
                                    cols={30}
                                    maxLength={250}
                                    placeholder="Enter Description"
                                    id="description"
                                    name="description"
                                    value={formik?.values?.description?.replace(/\s\s+/g, " ")}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("description") }, "w-full md:w-10 inputClass")}
                                />
                                {getFormErrorMessage("description")}
                            </div>
                        </div>

                        {subEditable ?
                            <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Status <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Dropdown
                                        placeholder="--Please Select--"
                                        id="isActive"
                                        name="isActive"
                                        value={formik?.values?.isActive}
                                        onChange={formik.handleChange}
                                        className="w-full md:w-10 inputClass"
                                        options={status}
                                        optionLabel="name"
                                        optionValue="value"
                                    />
                                    {getFormErrorMessage("isActive")}
                                </div>
                            </div>
                            :
                            null
                        }

                        <div className="col-12 text-center">
                            <Button label="Cancel" onClick={(e) => handleCancel(e)} className="Cancelbtn p-mr-3" />
                            <Button loading={loading} type="submit" disabled={loading} iconPos="right" label={subEditable ? "Update" : "Save"} autoFocus className="Savebtn p-mr-3" />
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default AddEditSubCategory;
