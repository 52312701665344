//let baseURL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_LIVE;

//For Local
//  let baseURL = "http://192.168.12.97:3015/";

//For QA
// let baseURL = "http://20.212.227.60:3064/";

//For UAT
// let baseURL = "http://20.212.227.60:3065/";

//For Dev
// let baseURL = "http://20.212.227.60:3015/";

//For Shopez
let baseURL = "https://api.shopez.pk/";

export { baseURL };
