import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import MultiImage from "../../../components/MultiImage";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { ColorPicker } from "primereact/colorpicker";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import EditSizeDialog from "./edit_size_dialog";
import { Image } from "primereact/image";
import * as Yup from "yup";
import { Editor } from "primereact/editor";

//import { getClippingParents } from "@fullcalendar/core";
import { baseURL } from "../../../utilities/Config";
import { Chips } from "primereact/chips";
import { handleGetRequest } from "../../../service/GetTemplate";
import { handlePostRequest } from "../../../service/PostTemplate";
import { handlePatchRequest } from "../../../service/PatchTemplete";
import { toast } from "react-toastify";
import { confirmDialog } from 'primereact/confirmdialog';

const AddEditDialog = ({ onHide, editable, getProductData, productRowData }) => {

    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [yesStart, setYesStart] = useState(false);
    const [updateButton, setUpdateButton] = useState(false);
    const [disableColor, setDisableColor] = useState(false);
    const [editDisableColor, setEditDisableColor] = useState(false);
    const [editDisableSize, setEditDisableSize] = useState(false);
    const [allImages, setAllImages] = useState([]);
    const [featureImage, setFeatureImage] = useState("");
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState([]);
    const [showAddMoreColorButton, setShowAddMoreColorButton] = useState(false);
    const [existingVariant, setExistingVariant] = useState(null);
    const [oldImages, setOldImages] = useState([]);
    const [variants, setVariants] = useState([]);
    const [variantValidationMessages, setVariantValidationMessages] = useState({
        colorName: "",
        colorHex: "",
        actualPrice: "",
        discountedPrice: "",
        quantity: "",
        sku: "",
        size: "",
    });
    const dispatch = useDispatch();

    const [variant, setVariant] = useState({
        colorName: "",
        colorHex: "",
        actualPrice: "",
        discountedPrice: "",
        quantity: "",
        sku: "",
        size: "",
        image: "",
    });

    const [hasColors, setHasColors] = useState(false);
    const [hasSizes, setHasSizes] = useState(false);

    var validationSchema = Yup.object().shape({
        categoryId: Yup.mixed().required("This field is required"),
        subcategoryId: Yup.mixed().required("This field is required"),
        // isTaxable: Yup.mixed().required("This field is required"),
        name: Yup.mixed().required("This field is required"),
        title: Yup.mixed().required("This field is required"),
        // vendor: Yup.mixed().required("This field is required"),
        tags: Yup.mixed().required("This field is required"),
        metaData: Yup.array().min(1, "There must be at least one meta data").of(Yup.string().required()).required("This field is required"),
        longDescription: Yup.string().required("This field is required"),
        description: Yup.mixed().required("This field is required"),
        isDiscount: Yup.boolean().optional(),
        // variant:
        //     hasColors || hasSizes
        //         ? Yup.array().optional()
        //         : Yup.array().of(
        //               Yup.object({
        //                   sku: hasColors || hasSizes ? Yup.string("Sku is not string optional").optional() : Yup.string("Sku is not string required").required("This field is required"),
        //                   actualPrice: hasColors || hasSizes ? Yup.number("price is not number optional").optional() : Yup.number("Only number are accepted").required("This field is required"),
        //                   // discountedPrice: isDiscount ? Yup.string().required("This field is required") : Yup.string().optional(),
        //                   discountedPrice: Yup.number("Only number are accepted").when("isDiscount", {
        //                       is: true,
        //                       then: Yup.number("only number are accepted").required("This field is required 123"),
        //                       otherwise: Yup.number("only number are accepted").required("This field is required 124"),
        //                   }),
        //                   quantity: hasColors || hasSizes ? Yup.number("quantity is number optional").optional() : Yup.number("Only number are accepted").required("This field is required"),
        //               })
        //           ),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            categoryId: "",
            subcategoryId: "",
            name: "",
            isTaxable: "",
            title: "",
            description: "",
            longDescription: "",
            vendor: "",
            thumbnail: "",
            metaData: [],
            metaDescription: "",
            sku: "",
            actualPrice: "",
            discountedPrice: "",
            quantity: "",
            isActive: true,
            oneTimeDeal: false,
            isDiscount: false,
            tags: "",
            // taxAmount: "",
            images: [],
            variant: [
                {
                    colorName: "",
                    colorHex: "",
                    actualPrice: "",
                    discountedPrice: "",
                    quantity: "",
                    sku: "",
                    size: "",
                    image: "",
                },
            ],
        },

        onSubmit: async (data) => {
            console.log("data above", data)
            let isValidData = true;
            let validationMessages = {};
            if (!hasColors && !hasSizes) {
                if (!data.variant[0].sku) {
                    validationMessages.sku = "sku is required";
                    isValidData = false;
                }
                if (!data.variant[0].actualPrice) {
                    validationMessages.actualPrice = "Actual price is required";
                    isValidData = false;
                }
                if (!data.variant[0].quantity) {
                    validationMessages.quantity = "Quantity is required";
                    isValidData = false;
                }
                if (data.variant[0]?.quantity === 0) {
                    validationMessages.quantity = "Quantity should be greater than 0";
                    isValidData = false;
                }
                if (formik.values.isDiscount && !data.variant[0].discountedPrice) {
                    validationMessages.discountedPrice = "Discounted is required";
                    isValidData = false;
                }
            }

            if (!isValidData) {
                setVariantValidationMessages({ ...variantValidationMessages, ...validationMessages });
                return;
            }

            data.metaData = data?.metaData?.join(",") || "";

            if (hasColors || hasSizes) {

                const updatedVariants = variants.map((item) => {
                    return {
                        ...item,
                        sku: item.sku === `${item.size}${item.colorName}${item.sku}` ? item.sku : `${item.size}${item.colorName}/${item.sku}`,
                    };
                });

                console.log("updatedVariants", updatedVariants)
                data.variant = updatedVariants;
            }

            if (!hasColors && !hasSizes) {

                data.variant = variants;
            }

            console.log("Product data Submit", data)

            setLoading(true);
            if (editable) {
                setDisable(true);
                data.productId = productRowData;
                data.oldImages = oldImages;
                const res = await dispatch(handlePatchRequest(data, "api/v1/products/", true, true));
                if (res?.status === 200) {
                    onHide();
                    // formik.resetForm();
                    const newMetaData = formik.values.metaData.split(',').map(item => item.trim());
                    formik.setFieldValue('metaData', newMetaData)
                    getProductData();
                    setDisable(false);
                } else {
                }
                setDisable(false);
            } else {
                setDisable(true);
                const res = await dispatch(handlePostRequest(data, "api/v1/products/", true, true));

                if (res?.status === 200) {
                    onHide();
                    // formik.resetForm();
                    setDisable(false);
                    getProductData();
                } else {
                    const newMetaData = formik.values.metaData.split(',').map(item => item.trim());
                    formik.setFieldValue('metaData', newMetaData)
                    setDisable(false);
                }
            }
            setLoading(false)
        },
    });

    const getCategoryData = async () => {
        const res = await handleGetRequest("api/v1/category/all", false);
        if (res) {
            setCategory(res);
        }
    };

    const getProductById = async () => {
        setDisable(true);
        setLoading(true)
        const res = await handleGetRequest(`api/v1/products/detailsWeb?productId=${productRowData}`, false);
        setLoading(false)
        if (res) {
            setVariants(res.variant);
            setOldImages(res.images);

            if (res.variant.some((variant) => variant.colorName)) {
                setHasColors(true);
            }

            if (res.variant.some((variant) => variant.size)) {
                setHasSizes(true);
            }
            formik.setValues({ ...res, images: [], categoryId: res?.category?._id, subcategoryId: res?.subcategory?._id, metaData: res.metaData ? res.metaData.split(",") : [] });
            setYesStart(true)
            if (res?.variant[res?.variant.length - 1]?.discountedPrice !== null) {
                formik.setFieldValue('isDiscount', true)
            }
            else {
                formik.setFieldValue('isDiscount', false)
            }
        }
        setDisable(false);
    };

    const getSubCategoryData = async (category) => {
        const res = await handleGetRequest(`api/v1/category/subcatgeoriesportal?categoryId=${category}`, false);
        if (res) {
            setSubCategory(res.subcategories);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const isFormFieldValid2 = (name) => !!(formik.touched?.variant?.[0][name] && formik.errors.variant?.[0][name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) ? <small className="p-error">{formik.errors[name] ? formik.errors[name] : formik.errors.variant[0][name]}</small> : isFormFieldValid2(name) && <small className="p-error">{formik.errors[name] ? formik.errors[name] : formik.errors.variant[0][name]}</small>;
    };

    //Simple Add Additional  Case
    useEffect(() => {

        if (formik?.values?.isActive && yesStart === false && editable === false) {

            if (!hasColors && !hasSizes) {
                return null
            }

            else if (hasColors === true && hasSizes === false) {

                const filteredVariants = variants.filter(item => item?.colorName === '' && item?.colorHex === '');
                const filteredVariants2 = variants.filter(item => item?.size !== '');

                if (filteredVariants.length > 0 || filteredVariants2.length > 0) {
                    setVariants([]);
                    setVariant(prev => ({ ...prev, size: '' }))
                    return;
                }
                else {
                    return null;
                }
            }
            else if (hasSizes === true && hasColors === false) {

                const filteredVariants = variants.filter(item => item?.size === '');
                const filteredVariants2 = variants.filter(item => item?.colorName !== '' && item?.colorHex !== '');

                if (filteredVariants.length > 0 || filteredVariants2.length > 0) {
                    setVariants([]);
                    setVariant(prev => ({ ...prev, colorName: '', colorHex: '' }))
                    return;
                }
                else {
                    return null;
                }
            }

            else if (hasSizes === true && hasColors === true) {

                const filteredVariants = variants.filter(item => item?.size === '' || item?.colorName === '' || item?.colorHex === '');

                if (filteredVariants.length > 0) {
                    setVariants([]);
                    return;
                }
                else {
                    return null;
                }
            }

            else if (hasSizes === false && hasColors === false) {

                if (variants.length) {
                    const filteredVariants = variants.filter(item => item?.colorName !== '' || item?.colorHex !== '' || item?.size !== '');

                    if (filteredVariants.length > 0) {
                        setVariants([]);
                    }
                }
            }
            else {
                return null
            }
        }

    }, [editable, yesStart, formik?.values?.isActive, formik?.values?.isDiscount, hasColors, hasSizes, variants]);

    //Simple Edit Additional  Case
    useEffect(() => {

        if (formik?.values?.isActive && yesStart && editable) {

            if (!hasColors && !hasSizes) {
                return null
            }

            else if (hasColors === true && hasSizes === false) {

                const filteredVariants = variants.filter(item => item?.colorName === '' && item?.colorHex === '');
                const filteredVariants2 = variants.filter(item => item?.size !== '');

                if (filteredVariants.length > 0 || filteredVariants2.length > 0) {
                    setVariants([]);
                    setVariant(prev => ({ ...prev, size: '' }))
                    return;
                }
                else {
                    return null;
                }
            }

            else if (hasSizes === true && hasColors === false) {

                const filteredVariants = variants.filter(item => item?.size === '');
                const filteredVariants2 = variants.filter(item => item?.colorName !== '' && item?.colorHex !== '');

                if (filteredVariants.length > 0 || filteredVariants2.length > 0) {
                    setVariants([]);
                    return;
                }
                else {
                    return null;
                }
            }

            else if (hasSizes === true && hasColors === true) {

                const filteredVariants = variants.filter(item => item?.size === '' || item?.colorName === '' || item?.colorHex === '');

                if (filteredVariants.length > 0) {
                    setVariants([]);
                    setVariant(prev => ({ ...prev, colorName: '', colorHex: '' }))
                    return;
                }
                else {
                    return null;
                }
            }

            else if (hasSizes === false && hasColors === false) {
                if (variants.length) {
                    const filteredVariants = variants.filter(item => item?.colorName !== '' || item?.colorHex !== '' || item?.size !== '');
                    if (filteredVariants.length > 0) {
                        setVariants([]);
                    }
                }
            }

            else {
                return null
            }
        }

    }, [editable, yesStart, formik?.values?.isActive, formik?.values?.isDiscount, hasColors, hasSizes, variants]);

    useEffect(() => {
        if (editable && yesStart) {
            const result = variants.filter(item => item.colorName !== '' && item?.colorHex !== '')
            const result2 = variants.filter(item => item.size !== '')
            console.log("result", result)
            console.log("result2", result2)

            if (result?.length > 0) {
                setEditDisableColor(true)
            }
            else if (result?.length === 0) {
                setEditDisableColor(false)
            }

            if (result2?.length > 0) {
                setEditDisableSize(true)
            }
            else if (result2?.length === 0) {
                setEditDisableSize(false)
            }
        }
    }, [editable, variants, hasColors, hasSizes, editDisableColor, editDisableSize, yesStart])

    console.log("variant", variant)

    //Callback Function to Get Base64 of Uploaded Image
    const handleImages = (images) => {
        formik.setFieldValue("images", images);
        setAllImages(images);
        setFeatureImage(images[0]);
    };

    const variantChangeHandler = (e) => {

        // Check if the target's name is "quantity" and its value is "0"
        if (e.target.name === "quantity" && e.target.value === "0") {
            // If it lands on 0, set the value to an empty string
            e.target.value = "";
            setVariantValidationMessages({ ...variantValidationMessages, [e.target.name]: "Quantity should be greater than 0" });
        } else {
            // If not, reset the validation message
            setVariantValidationMessages({ ...variantValidationMessages, [e.target.name]: e.target.value ? "" : e.target.placeholder + " is required" });
        }

        const updatedVariant = {
            ...variant,
            [e.target.name]: e.target.value,
        };
        console.log("updatedVariant", updatedVariant)

        setVariant(updatedVariant);
    };

    const handleAddMoreColor = (e) => {
        setShowAddMoreColorButton(false);
        setVariant({
            colorName: "",
            colorHex: "",
            actualPrice: "",
            discountedPrice: "",
            quantity: "",
            sku: "",
            size: "",
            image: "",
        });
    };

    const handleAddNewColor = () => {
        setDisableColor(false)
        variant.colorName = ''
        variant.colorHex = ''
    }

    const handleAddVariant = async (e) => {

        let isValidData = true;
        let validationMessages = {};

        if (hasColors) {
            if (!variant.colorName) {
                validationMessages.colorName = "Color name is required";
                isValidData = false;
            }
            if (!variant.colorHex) {
                validationMessages.colorHex = "Color is required";
                isValidData = false;
            }
            if (!variant.sku) {
                validationMessages.sku = "sku is required";
                isValidData = false;
            }
            if (!variant.actualPrice) {
                validationMessages.actualPrice = "Actual price is required";
                isValidData = false;
            }
            if (!variant.quantity) {
                validationMessages.quantity = "Quantity is required";
                isValidData = false;
            }
            if (formik.values.isDiscount && !variant.discountedPrice) {
                validationMessages.discountedPrice = "Discounted is required";
                isValidData = false;
            }
        }

        if (hasSizes) {
            if (!variant.size) {
                validationMessages.size = "Size is required";
                isValidData = false;
            }
            if (!variant.sku) {
                validationMessages.sku = "sku is required";
                isValidData = false;
            }
            if (!variant.actualPrice) {
                validationMessages.actualPrice = "Actual price is required";
                isValidData = false;
            }
            if (!variant.quantity) {
                validationMessages.quantity = "Quantity is required";
                isValidData = false;
            }
            if (formik.values.isDiscount && !variant.discountedPrice) {
                validationMessages.discountedPrice = "Discounted is required";
                isValidData = false;
            }
        }

        if (hasColors && hasSizes) {
            const { colorName, size, sku } = variant;
            const isAlreadyAdd = variants.find((item) => item.size == size && item.colorName == colorName && item.sku.toLowerCase() == sku.toLowerCase());
            if (isAlreadyAdd && existingVariant == null) {
                toast.warn("Size is already added with same sku and color");
                return;
            }
            if (!variant.colorName) {
                validationMessages.colorName = "Color name is required";
                isValidData = false;
            }
            if (!variant.colorHex) {
                validationMessages.colorHex = "Color is required";
                isValidData = false;
            }
            if (!variant.size) {
                validationMessages.size = "Size is required";
                isValidData = false;
            }
            if (!variant.sku) {
                validationMessages.sku = "sku is required";
                isValidData = false;
            }
            if (!variant.actualPrice) {
                validationMessages.actualPrice = "Actual price is required";
                isValidData = false;
            }
            if (!variant.quantity) {
                validationMessages.quantity = "Quantity is required";
                isValidData = false;
            }
            if (formik.values.isDiscount && !variant.discountedPrice) {
                validationMessages.discountedPrice = "Discounted is required";
                isValidData = false;
            }
        }

        if (hasColors && hasSizes === false) {
            console.log("asas", variant)
            const { colorName, sku } = variant;
            const isAlreadyAdd2 = variants.find((item) => item.colorName.toLowerCase() == colorName.toLowerCase() && item.sku.toLowerCase() == sku.toLowerCase());
            if (isAlreadyAdd2 && existingVariant == null) {
                toast.warn("Color is already added with same sku");
                return;
            }
        }

        if (!isValidData) {
            setVariantValidationMessages({ ...variantValidationMessages, ...validationMessages });
            return;
        }

        if (existingVariant !== null) {
            console.log('here')
            const data = variants.map((item, index) => {
                if (index == existingVariant) {
                    return variant;
                }
                return item;
            });
            setVariants(data);
            setExistingVariant(null);
            setShowAddMoreColorButton(false);

        } else {
            console.log('there')
            setVariants((prev) => {
                return [...prev, variant];
            });
        }
        if (hasColors && hasSizes && existingVariant == null) {
            setShowAddMoreColorButton(true);
            setVariant({
                ...variant,
                actualPrice: "",
                discountedPrice: "",
                quantity: "",
                size: "",
                image: "",
            });
        }

        else {
            setVariant({
                colorName: "",
                colorHex: "",
                actualPrice: "",
                discountedPrice: "",
                quantity: "",
                sku: "",
                size: "",
                image: "",
            });
        }
    };

    const deleteVariant = (index) => {
        const data = variants.filter((data, dataIndex) => {
            return index != dataIndex;
        });
        setVariants(data);
    };

    const handleAddProduct = async (e) => {

        if (variants.length === 1 && hasColors === false && hasSizes === false) {
            const updatedVariant = {
                sku: formik?.values?.variant[0]?.sku,
                actualPrice: formik?.values?.variant[0]?.actualPrice,
                discountedPrice: formik?.values?.variant[0]?.discountedPrice,
                quantity: formik?.values?.variant[0]?.quantity,
                size: '',
                colorName: '',
                colorHex: '',
            };

            setVariants([updatedVariant]);
            setExistingVariant(null)
        }

        else if (variants.length === 0) {

            setVariants([
                ...variants,
                {
                    colorHex: "",
                    colorName: "",
                    actualPrice: formik.values.variant[0].actualPrice,
                    discountedPrice: formik.values.variant[0].discountedPrice,
                    quantity: formik.values.variant[0].quantity,
                    size: "",
                    sku: formik.values.variant[0].sku,
                }
            ]);

        }

    };

    const handleAddProductUpdate = async (e) => {

        setUpdateButton(true)
        if (variants.length === 1 && hasColors === false && hasSizes === false) {
            const updatedVariant = {
                sku: formik?.values?.variant[0]?.sku,
                actualPrice: formik?.values?.variant[0]?.actualPrice,
                discountedPrice: formik?.values?.variant[0]?.discountedPrice,
                quantity: formik?.values?.variant[0]?.quantity,
                size: '',
                colorName: '',
                colorHex: '',
            };

            setVariants([updatedVariant]);
            setExistingVariant(null)
        }

        else if (variants.length === 0) {

            setVariants([
                ...variants,
                {
                    colorHex: "",
                    colorName: "",
                    actualPrice: formik.values.variant[0].actualPrice,
                    discountedPrice: formik.values.variant[0].discountedPrice,
                    quantity: formik.values.variant[0].quantity,
                    size: "",
                    sku: formik.values.variant[0].sku,
                }
            ]);

        }

    };

    console.log("variants", variants)

    useEffect(() => {
        getCategoryData();

        if (editable) {
            getProductById();
        }
    }, []);

    useEffect(() => {
        if (formik.values.categoryId) {
            getSubCategoryData(formik.values.categoryId);
        }
    }, [formik.values.categoryId]);

    useEffect(() => {
        setIsDiscount(formik.values.isDiscount);
    }, [formik.values.isDiscount]);

    const TaxAbleOptions = [
        { name: "Yes", value: true },
        { name: "No", value: false },
    ]

    const actionBodyTemplate = (rowData, rowIndex) => {

        return (
            <>
                {
                    <Button
                        type="button"
                        onClick={(e) => {
                            setExistingVariant(rowIndex.rowIndex);
                            setVariant(rowData);
                        }}
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-text"
                        aria-label="Edit"
                    />
                }

                {
                    <Button
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-text"
                        aria-label="Delete"
                        onClick={(e) => {
                            setShowAddMoreColorButton(false);
                            deleteVariant(rowIndex.rowIndex);
                        }}
                    />
                }

                {hasColors && hasSizes ?
                    <Button
                        type="button"
                        onClick={(e) => {
                            setVariant(prevVariant => ({ ...prevVariant, colorName: rowData?.colorName, colorHex: rowData?.colorHex, sku: rowData?.sku }))
                        }}
                        icon="pi pi-plus"
                        className="p-button-rounded p-button-text"
                        aria-label="Add Size"
                        tooltip="Add size with same color"
                        tooltipOptions={{ position: "top" }}
                    />
                    :
                    null
                }
            </>
        );
    };

    const productTypeComponent = () => {

        const handleHasColorsChange = (e) => {
            confirmDialog({
                message: hasColors === true ? "All previously added variants will be removed. Do you want to proceed?" : "All previously added variants will be removed. Do you want to proceed?",
                header: "Color Confirmation",
                icon: "pi pi-check",
                acceptClassName: "Savebtn",
                rejectClassName: "Cancelbtn",
                accept: () => setHasColors(e.checked),
                reject: () => { }
            });
        };

        const handleHasSizesChange = (e) => {
            confirmDialog({
                message: hasSizes === true ? "All previously added variants will be removed. Do you want to proceed?" : "All previously added variants will be removed. Do you want to proceed?",
                header: "Size Confirmation",
                icon: "pi pi-check",
                acceptClassName: "Savebtn",
                rejectClassName: "Cancelbtn",
                accept: () => setHasSizes(e.checked),
                reject: () => { }
            });
        };

        return (
            <>
                <div className="flex flex-column ml-3">
                    <label htmlFor="cb2" className="mb-2">
                        Has colors
                    </label>
                    <Checkbox disabled={editDisableColor} inputId="cb2" onChange={variants?.length ? handleHasColorsChange : (e) => setHasColors(e.checked)} checked={hasColors}></Checkbox>
                </div>

                <div className="flex flex-column ml-3">
                    <label htmlFor="cb3" className="mb-2">
                        Has sizes
                    </label>
                    <Checkbox disabled={editDisableSize} inputId="cb3" onChange={variants.length ? handleHasSizesChange : (e) => setHasSizes(e.checked)} checked={hasSizes}></Checkbox>
                </div>
            </>
        );
    };

    console.log('formik.values.description', formik.values.description)

    useEffect(() => { }, [variantValidationMessages]);

    const simpleVariant = () => {
        return (
            <div>
                <div id="add-variant-form">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Sku<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    placeholder="Sku"
                                    maxLength={20}
                                    name="variant[0].sku"
                                    value={formik?.values?.variant[0]?.sku}
                                    onKeyDown={() => {
                                        setVariantValidationMessages({ ...variantValidationMessages, sku: "" });
                                    }}
                                    onChange={formik.handleChange}
                                    keyfilter={/^[^\s]*$/}
                                    className="w-full md:w-10 inputClass"
                                />

                                {variantValidationMessages.sku && <small className="p-error">{variantValidationMessages.sku}</small>}
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Actual Price<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    name="variant[0].actualPrice"
                                    value={formik?.values?.variant[0]?.actualPrice}
                                    onKeyDown={() => {
                                        setVariantValidationMessages({ ...variantValidationMessages, actualPrice: "" });
                                    }}
                                    onChange={(e) => {
                                        const actualPrice = parseFloat(e.target.value);
                                        const discountedPrice = parseFloat(formik.values.variant[0].discountedPrice);
                                        if (!isNaN(discountedPrice) && !isNaN(actualPrice) && discountedPrice >= actualPrice) {
                                            // Display an error message or handle the situation where discounted price is greater than actual price
                                            setVariantValidationMessages({
                                                ...variantValidationMessages,
                                                actualPrice: "Discounted price cannot be greater than actual price",
                                            });
                                        } else {
                                            // Clear the error message if the condition is not met
                                            setVariantValidationMessages({ ...variantValidationMessages, actualPrice: "" });
                                            formik.handleChange(e);
                                        }
                                    }}
                                    placeholder="Actual price"
                                    type="text"
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    min="1"
                                    maxLength={7}
                                    className="w-full md:w-10 inputClass"
                                />
                                {variantValidationMessages.actualPrice && <small className="p-error">{variantValidationMessages.actualPrice}</small>}
                            </div>
                        </div>
                        {isDiscount && (
                            <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Discounted Price<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText
                                        name="variant[0].discountedPrice"
                                        value={formik?.values?.variant[0]?.discountedPrice}
                                        onKeyDown={() => {
                                            setVariantValidationMessages({ ...variantValidationMessages, discountedPrice: "" });
                                        }}
                                        onChange={(e) => {
                                            const discountedPrice = parseFloat(e.target.value);
                                            const actualPrice = parseFloat(formik.values.variant[0].actualPrice);
                                            if (!isNaN(discountedPrice) && !isNaN(actualPrice) && discountedPrice >= actualPrice) {
                                                // Display an error message or handle the situation where discounted price is greater than actual price
                                                setVariantValidationMessages({
                                                    ...variantValidationMessages,
                                                    discountedPrice: "Discounted price cannot be greater than actual price",
                                                });
                                            } else {
                                                // Clear the error message if the condition is not met
                                                setVariantValidationMessages({ ...variantValidationMessages, discountedPrice: "" });
                                                formik.handleChange(e);
                                            }
                                        }}
                                        placeholder="Discounted price"
                                        type="text"
                                        min="0"
                                        maxLength={7}
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        className="w-full md:w-10 inputClass"
                                    />
                                    {variantValidationMessages.discountedPrice && <small className="p-error">{variantValidationMessages.discountedPrice}</small>}
                                </div>
                            </div>
                        )}
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Quantity<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    name="variant[0].quantity"
                                    value={formik?.values?.variant[0]?.quantity}
                                    onKeyDown={() => {
                                        setVariantValidationMessages({ ...variantValidationMessages, quantity: "" });
                                    }}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        if (name === "variant[0].quantity" && value === "0") {
                                            setVariantValidationMessages({ ...variantValidationMessages, quantity: "Quantity should be greater than 0" });
                                        } else {
                                            setVariantValidationMessages({ ...variantValidationMessages, quantity: "" });
                                            formik.handleChange(e);
                                        }
                                    }}
                                    placeholder="Quantity"
                                    type="text"
                                    min="0"
                                    maxLength={6}
                                    keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                    className="w-full md:w-10 inputClass"
                                />
                                {variantValidationMessages.quantity && <small className="p-error">{variantValidationMessages.quantity}</small>}
                            </div>
                        </div>
                    </div>

                    {(variants.length === 0 && hasColors === false && hasSizes === false)
                        ?
                        <div className="col-12 text-right">
                            <Button type="button" disabled={formik?.values?.variant[0]?.sku === '' || formik?.values?.variant[0]?.actualPrice === '' || formik?.values?.variant[0]?.quantity === '' ? true : false} form="add-variant-form" onClick={handleAddProduct} iconPos="right" label="Add Product" className="Savebtn p-mr-3" />
                        </div>
                        :
                        null}

                    {(variants.length === 1 && existingVariant !== null && hasColors === false && variant?.sku !== "" && hasSizes === false) || (variants.length === 1 && formik?.values?.isActive && formik?.values?.isDiscount && variant?.sku !== "" && hasColors === false && hasSizes === false)
                        ?
                        <div className="col-12 text-right">
                            <Button type="button" form="add-variant-form" disabled={formik?.values?.variant[0]?.sku === '' || formik?.values?.variant[0]?.actualPrice === '' || formik?.values?.variant[0]?.quantity === '' ? true : false} onClick={handleAddProductUpdate} iconPos="right" label="Update Product" className="Savebtn2 p-mr-3" />
                        </div>
                        :
                        null
                    }
                </div>

                {variants.length ? (
                    <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="innr-Body">
                            <DataTable responsiveLayout="scroll" value={variants}>
                                <Column field="sku" header="Sku" body={(rowData) => rowData.sku.includes('/') ? rowData.sku?.split('/')[1] : rowData.sku} />
                                <Column field="actualPrice" header="Actual Price" />
                                <Column field="quantity" header="Quantity" />
                                <Column field="colorName" header="Color name" body={(rowData) => rowData.colorName ? rowData.colorName : "N/A"} />
                                <Column field="colorHex" header="Color Hex" body={(rowData) => rowData.colorHex ? rowData.colorHex : "N/A"} />
                                <Column field="size" header="Size" body={(rowData) => rowData.size ? rowData.size : "N/A"} />
                                <Column field="discountedPrice" header="Discounted Price" body={(rowData) => (rowData.discountedPrice ? rowData.discountedPrice : 'N/A')} />
                                <Column header="Action" body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                )
                    :
                    null}
            </div>

        );
    };

    const colorNoSizeComponent = () => {
        return (
            <div>
                <div id="add-variant-form">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Color Name<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText maxLength={10} name="colorName" value={variant.colorName} onChange={variantChangeHandler} placeholder="Color name" className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.colorName && <small className="p-error">{variantValidationMessages.colorName}</small>}
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Color Picker<span style={{ color: "red" }}>*</span>
                                </label>
                                <ColorPicker placeholder="Color" name="colorHex" value={variant.colorHex} onChange={variantChangeHandler} className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.colorHex && <small className="p-error">{variantValidationMessages.colorHex}</small>}
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Sku<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText placeholder="Sku" name="sku" maxLength={20} value={variant.sku} onChange={variantChangeHandler} keyfilter={/^[^\s]*$/} className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.sku && <small className="p-error">{variantValidationMessages.sku}</small>}
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Actual Price<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText name="actualPrice" value={variant.actualPrice}
                                    onChange={(e) => {
                                        const actualPrice = parseFloat(e.target.value);
                                        const discountedPrice = parseFloat(variant.discountedPrice);
                                        if (!isNaN(discountedPrice) && !isNaN(actualPrice) && discountedPrice >= actualPrice) {
                                        } else {
                                            variantChangeHandler(e);
                                        }
                                    }} placeholder="Actual price" type="text" keyfilter={/^[0-9]*(\.[0-9]*)?$/} min="1" maxLength={7} className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.actualPrice && <small className="p-error">{variantValidationMessages.actualPrice}</small>}
                        </div>
                        {formik.values.isDiscount && (
                            <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Discounted Price<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText name="discountedPrice" value={variant.discountedPrice} onChange={(e) => {
                                        const discountedPrice = parseFloat(e.target.value);
                                        const actualPrice = parseFloat(variant.actualPrice);
                                        if (!isNaN(discountedPrice) && !isNaN(actualPrice) && discountedPrice >= actualPrice) {
                                        } else {
                                            variantChangeHandler(e);
                                        }
                                    }} placeholder="Discounted price" type="text" min="0" maxLength={7} keyfilter={/^[0-9]*(\.[0-9]*)?$/} className="w-full md:w-10 inputClass" />
                                </div>
                                {variantValidationMessages.discountedPrice && <small className="p-error">{variantValidationMessages.discountedPrice}</small>}
                            </div>
                        )}
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Quantity<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText name="quantity" value={variant.quantity} onChange={variantChangeHandler} placeholder="Quantity" type="text" min="0" maxLength={6} keyfilter={/^[0-9]*(\.[0-9]*)?$/} className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.quantity && <small className="p-error">{variantValidationMessages.quantity}</small>}
                        </div>

                        <div className="text-right col-12">
                            <Button type="button" form="add-variant-form" onClick={handleAddVariant} disabled={loading} iconPos="right" label={existingVariant != null ? "Update Color" : "Add Color"} className="Savebtn p-mr-3" />
                        </div>
                    </div>
                </div>

                {!hasSizes && hasColors && (
                    <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="innr-Body">
                            <DataTable responsiveLayout="scroll" value={variants}>
                                <Column field="sku" header="Sku" body={(rowData) => rowData.sku.includes('/') ? rowData.sku?.split('/')[1] : rowData.sku} />
                                <Column field="actualPrice" header="Actual Price" />
                                <Column field="quantity" header="Quantity" />
                                <Column field="colorName" header="Color name" body={(rowData) => rowData.colorName ? rowData.colorName : "N/A"} />
                                <Column field="colorHex" header="Color Hex" body={(rowData) => rowData.colorHex ? rowData.colorHex : "N/A"} />
                                <Column field="size" header="Size" body={(rowData) => rowData.size ? rowData.size : "N/A"} />
                                <Column field="discountedPrice" header="Discounted Price" body={(rowData) => (rowData.discountedPrice ? rowData.discountedPrice : 'N/A')} />
                                <Column header="Action" body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const noColorSizeComponent = () => {
        return (
            <div>
                <div id="add-variant-form">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Size<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText name="size" maxLength={10} value={variant.size} onChange={variantChangeHandler} placeholder="Enter Size" className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.size && <small className="p-error">{variantValidationMessages.size}</small>}
                        </div>
                        {!hasColors && (
                            <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Sku<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText name="sku" maxLength={20} value={variant.sku} keyfilter={/^[^\s]*$/} onChange={variantChangeHandler} placeholder="Sku" className="w-full md:w-10 inputClass" />
                                </div>
                                {variantValidationMessages.sku && <small className="p-error">{variantValidationMessages.sku}</small>}
                            </div>
                        )}
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Actual Price<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText maxLength={7} name="actualPrice" value={variant.actualPrice} onChange={(e) => {
                                    const actualPrice = parseFloat(e.target.value);
                                    const discountedPrice = parseFloat(variant.discountedPrice);
                                    if (!isNaN(discountedPrice) && !isNaN(actualPrice) && discountedPrice >= actualPrice) {
                                    } else {
                                        variantChangeHandler(e);
                                    }
                                }}
                                    placeholder="Actual Price" keyfilter={/^[0-9]*(\.[0-9]*)?$/} min="1" className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.actualPrice && <small className="p-error">{variantValidationMessages.actualPrice}</small>}
                        </div>
                        {formik.values.isDiscount && (
                            <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Discounted Price<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText
                                        maxLength={7}
                                        name="discountedPrice"
                                        value={variant.discountedPrice}
                                        onChange={(e) => {
                                            const discountedPrice = parseFloat(e.target.value);
                                            const actualPrice = parseFloat(variant.actualPrice);
                                            if (!isNaN(discountedPrice) && !isNaN(actualPrice) && discountedPrice >= actualPrice) {
                                            } else {
                                                variantChangeHandler(e);
                                            }
                                        }}
                                        placeholder="Discounted Price"
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        min="0"
                                        className="w-full md:w-10 inputClass"
                                    />
                                </div>
                                {variantValidationMessages.discountedPrice && <small className="p-error">{variantValidationMessages.discountedPrice}</small>}
                            </div>
                        )}
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Quantity<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText maxLength={6} name="quantity" value={variant.quantity} onChange={variantChangeHandler} placeholder="Quantity" min="0" keyfilter={/^[0-9]*(\.[0-9]*)?$/} className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.quantity && <small className="p-error">{variantValidationMessages.quantity}</small>}
                        </div>

                        <div className="col-12 text-right">
                            <Button type="button" form="add-variant-form" onClick={handleAddVariant} disabled={loading} iconPos="right" label={existingVariant != null ? "Update Size" : "Add Size"} className="Savebtn p-mr-3" />
                        </div>
                    </div>
                </div>

                {hasSizes && !hasColors && (
                    <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="innr-Body">
                            <DataTable responsiveLayout="scroll" value={variants}>
                                <Column field="sku" header="Sku" body={(rowData) => rowData.sku.includes('/') ? rowData.sku?.split('/')[1] : rowData.sku} />
                                <Column field="actualPrice" header="Actual Price" />
                                <Column field="quantity" header="Quantity" />
                                <Column field="colorName" header="Color name" body={(rowData) => rowData.colorName ? rowData.colorName : "N/A"} />
                                <Column field="colorHex" header="Color Hex" body={(rowData) => rowData.colorHex ? rowData.colorHex : "N/A"} />
                                <Column field="size" header="Size" body={(rowData) => rowData.size ? rowData.size : "N/A"} />
                                <Column field="discountedPrice" header="Discounted Price" body={(rowData) => (rowData.discountedPrice ? rowData.discountedPrice : 'N/A')} />
                                <Column field="quantity" header="Quantity" />
                                <Column header="Action" body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const colorSizeComponent = () => {
        return (
            <div>
                <div id="add-variant-form">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Color Name<span style={{ color: "red" }}>*</span>
                                </label>
                                {/* <InputText disabled={showAddMoreColorButton && existingVariant == null} name="colorName" value={variant.colorName} onChange={variantChangeHandler} placeholder="Color name" className="w-full md:w-10 inputClass" /> */}
                                <InputText maxLength={10} name="colorName" disabled={showAddMoreColorButton && existingVariant == null} value={variant.colorName} onChange={variantChangeHandler} placeholder="Color name" className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.colorName && <small className="p-error">{variantValidationMessages.colorName}</small>}
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Color Picker<span style={{ color: "red" }}>*</span>
                                </label>
                                <ColorPicker placeholder="Color" disabled={showAddMoreColorButton && existingVariant == null} name="colorHex" value={variant.colorHex} onChange={variantChangeHandler} className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.colorHex && <small className="p-error">{variantValidationMessages.colorHex}</small>}
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 xl:col-6">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Sku<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText keyfilter={/^[^\s]*$/} name="sku" disabled={showAddMoreColorButton && existingVariant == null} maxLength={20} value={variant.sku} onChange={variantChangeHandler} placeholder="Sku" className="w-full md:w-10 inputClass" />
                            </div>
                            {variantValidationMessages.sku && <small className="p-error">{variantValidationMessages.sku}</small>}
                        </div>

                        <div className="col-12 text-right">
                            {/* {variants.length ?
                                <Button type="button" form="add-variant-form" onClick={handleAddNewColor} iconPos="right" label="Add New Color" className="Savebtn" />
                                :
                                null} */}
                            {showAddMoreColorButton && existingVariant == null && <Button type="button" form="add-variant-form" onClick={handleAddMoreColor} iconPos="right" label={existingVariant != null ? "Update Color" : "Add color"} className="Savebtn p-mr-3 ml-2" />}{" "}
                        </div>

                        <div className="col-12">
                            <h3>Sizes</h3>
                        </div>
                        <div className="col-12">{noColorSizeComponent(false)}</div>
                    </div>
                </div>

                {hasColors && hasSizes && (
                    <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                        <div className="innr-Body">
                            <DataTable responsiveLayout="scroll" value={variants}>
                                <Column field="sku" header="Sku" body={(rowData) => rowData.sku.includes('/') ? rowData.sku?.split('/')[1] : rowData.sku} />
                                <Column field="actualPrice" header="Actual Price" />
                                <Column field="quantity" header="Quantity" />
                                <Column field="colorName" header="Color name" body={(rowData) => rowData.colorName ? rowData.colorName : "N/A"} />
                                <Column field="colorHex" header="Color Hex" body={(rowData) => rowData.colorHex ? rowData.colorHex : "N/A"} />
                                <Column field="size" header="Size" body={(rowData) => rowData.size ? rowData.size : "N/A"} />
                                <Column field="discountedPrice" header="Discounted Price" body={(rowData) => (rowData.discountedPrice ? rowData.discountedPrice : 'N/A')} />
                                <Column header="Action" body={actionBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Category<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Dropdown
                                        placeholder="--Please Select--"
                                        id="categoryId"
                                        name="categoryId"
                                        className={classNames({ "p-invalid": isFormFieldValid("categoryId") }, "w-full md:w-10 inputClass")}
                                        value={formik.values.categoryId}
                                        options={category}
                                        onChange={formik.handleChange}
                                        optionValue="_id"
                                        optionLabel="name"
                                    />
                                    {getFormErrorMessage("categoryId")}
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Sub-Category <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Dropdown
                                        placeholder="--Please Select--"
                                        id="subcategoryId"
                                        name="subcategoryId"
                                        className={classNames({ "p-invalid": isFormFieldValid("subcategoryId") }, "w-full md:w-10 inputClass")}
                                        value={formik.values.subcategoryId}
                                        options={subCategory}
                                        onChange={formik.handleChange}
                                        optionValue="_id"
                                        optionLabel="name"
                                    />
                                    {getFormErrorMessage("subcategoryId")}
                                </div>
                            </div>
                            {/* <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Taxable <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Dropdown
                                        placeholder="--Please Select--"
                                        id="isTaxable"
                                        name="isTaxable"
                                        className={classNames({ "p-invalid": isFormFieldValid("isTaxable") }, "w-full md:w-10 inputClass")}
                                        value={formik.values.isTaxable}
                                        options={TaxAbleOptions}
                                        onChange={formik.handleChange}
                                        optionValue="value"
                                        optionLabel="name"
                                    />
                                    {getFormErrorMessage("isTaxable")}
                                </div>
                            </div> */}
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Product Name <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText
                                        maxLength={40}
                                        minLength={3}
                                        placeholder="Enter Product Name"
                                        id="name"
                                        name="name"
                                        value={formik?.values?.name?.replace(/\s\s+/g, " ")}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("name") }, "w-full md:w-10 inputClass")}
                                    />
                                    {getFormErrorMessage("name")}
                                </div>
                            </div>
                            {/* 
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">Tax Amount</label>
                                    <InputText
                                        disabled
                                        maxLength={6}
                                        keyfilter="int"
                                        placeholder="Enter Tax Amount"
                                        id="taxAmount"
                                        name="taxAmount"
                                        value={formik?.values?.taxAmount}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("taxAmount") }, "w-full md:w-10 inputClass")}
                                    />
                                    {getFormErrorMessage("taxAmount")}
                                </div>
                            </div> */}

                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Product Title <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText
                                        maxLength={140}
                                        minLength={3}
                                        placeholder="Enter Product Title"
                                        id="title"
                                        name="title"
                                        value={formik?.values?.title?.replace(/\s\s+/g, " ")}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("title") }, "w-full md:w-10 inputClass")}
                                    />
                                    {getFormErrorMessage("title")}
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Vendor
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                    </label>
                                    <InputText placeholder="Enter Vendor" maxLength={40}
                                        minLength={3} id="vendor" name="vendor" value={formik?.values?.vendor?.replace(/\s\s+/g, " ")} onChange={formik.handleChange} className="w-full md:w-10 inputClass" />
                                    {/* {getFormErrorMessage("vendor")} */}
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Tags <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <InputText
                                        id="tags"
                                        name="tags"
                                        maxLength={50}
                                        value={formik?.values?.tags}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("tags") }, "w-full md:w-10 inputClass")}
                                    //separator=","
                                    />
                                    {getFormErrorMessage("tags")}
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Long Description <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Editor
                                        id="longDescription"
                                        name="longDescription"
                                        value={formik.values.longDescription}
                                        onTextChange={(e) => formik.setFieldValue('longDescription', e.htmlValue)}
                                        style={{ height: '200px' }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('longDescription') }, 'w-full md:w-10 inputClass')}
                                    />
                                    {/* <InputTextarea
                                        rows={5}
                                        cols={30}
                                        maxLength={250}
                                        placeholder="Enter Long Description"
                                        id="longDescription"
                                        name="longDescription"
                                        value={formik?.values?.longDescription?.replace(/\s\s+/g, " ")}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("longDescription") }, "w-full md:w-10 inputClass")}
                                    /> */}
                                    {getFormErrorMessage("longDescription")}
                                </div>
                            </div>

                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Description <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Editor
                                        id="description"
                                        name="description"
                                        value={formik.values.description}
                                        onTextChange={(e) => formik.setFieldValue('description', e.htmlValue)}
                                        style={{ height: '200px' }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'w-full md:w-10 inputClass')}
                                    />
                                    {getFormErrorMessage("description")}
                                    {/* <InputTextarea
                                        rows={5}
                                        cols={30}
                                        maxLength={250}
                                        placeholder="Enter Description"
                                        id="description"
                                        name="description"
                                        value={formik?.values?.description?.replace(/\s\s+/g, " ")}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("description") }, "w-full md:w-10 inputClass")}
                                    />
                                    {getFormErrorMessage("description")} */}
                                </div>
                            </div>

                            <div className="col-12 md:col-4">

                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Meta Data <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Chips
                                        id="metaDataString"
                                        name="metaData"
                                        value={Array.isArray(formik?.values?.metaData) ? formik?.values?.metaData.slice(0, 10) : []} // Limit to 10 chips
                                        onChange={(e) => {
                                            let newValue = e.value.slice(0, 10); // Limit to 10 chips
                                            // Convert all values to lowercase
                                            newValue = newValue.map(item => item.toLowerCase());
                                            // Remove duplicate values
                                            newValue = newValue.filter((item, index, self) => self.indexOf(item) === index);
                                            formik.handleChange({ target: { name: "metaData", value: newValue } });
                                        }}
                                        className={classNames({ "p-invalid": isFormFieldValid("metaData") }, "w-full md:w-10 inputClass")}
                                        separator=","
                                    />
                                    {getFormErrorMessage("metaData")}
                                </div>

                            </div>
                            <div className="col-12 md:col-4">
                                <div className="flex flex-column">
                                    <label className="mb-2">Meta Description</label>
                                    <InputTextarea
                                        rows={5}
                                        cols={30}
                                        maxLength={250}
                                        placeholder="Enter Meta Description"
                                        id="metaDescription"
                                        name="metaDescription"
                                        value={formik?.values?.metaDescription?.replace(/\s\s+/g, " ")}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("metaDescription") }, "w-full md:w-10 inputClass")}
                                    />
                                </div>


                            </div>

                            <div className="col-12 md:col-12 lg:col-12 xl:col-12">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Images <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="flex flex-row">
                                        {oldImages.map((item, index) => {
                                            return (
                                                <div key={`${index} images`} className="relative">
                                                    <div style={{ position: "relative", top: "16px", left: "72px", bottom: "52px", zIndex: "2", color: "red" }}>
                                                        <span
                                                            className="removeImageIcon"
                                                            onClick={() => {
                                                                const filterImages = oldImages.filter((image, i) => {
                                                                    return i !== index;
                                                                });
                                                                setOldImages(filterImages);
                                                            }}
                                                        >
                                                            x
                                                        </span>
                                                    </div>
                                                    <Image className="mx-2" height="80px" width="80px" preview src={`${baseURL}${item}`} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <MultiImage oldImages={oldImages} handleImages={handleImages} />
                                </div>
                            </div>

                            <div className="col-12 md:col-12 flex mb-3">
                                <div className="flex flex-column">
                                    <label className="mb-2">is Active?</label>
                                    <Checkbox id="isActive" name="isActive" inputId="binary" checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                <div className="flex flex-column ml-3">
                                    <label className="mb-2">is Discount?</label>
                                    <Checkbox id="isDiscount" name="isDiscount" inputId="binary" checked={formik?.values?.isDiscount} onChange={formik.handleChange} />
                                </div>
                                {productTypeComponent()}
                            </div>
                            {/* <div style={{ display: 'flex', alignItems: 'center' }} className="ml-2 mb-4">
                                <icon className='pi pi-info-circle' />
                                <small className="ml-2" style={{ fontSize: "14px", color: "#ff4949" }}>
                                    Product variants can have either of no color and no sizes at all, all colors and no sizes at all, all sizes and no colors at all, or all sizes and all colors at all.
                                </small>
                            </div> */}
                        </div>
                        {!hasColors && !hasSizes && simpleVariant()}
                        {hasColors && !hasSizes && colorNoSizeComponent()}
                        {hasSizes && !hasColors && noColorSizeComponent()}
                        {hasSizes && hasColors && colorSizeComponent()}
                    </div>

                    <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                        <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                        <Button
                            type="submit"
                            disabled={
                                disable
                                ||
                                variants.length === 0
                                ||
                                (editable === false && allImages?.length === 0 ? true : false)
                                ||
                                (editable === true && oldImages?.length === 0 && allImages.length === 0 ? true : false)
                                ||
                                (!hasColors && !hasSizes && variants.length === 1 && variant?.sku !== '' && !updateButton)
                            }
                            loading={loading}
                            iconPos="right"
                            label={editable ? "Update" : "Save"}
                            autoFocus
                            className="Savebtn p-mr-3"
                        />
                    </div>
                </form>
            )}
        </>
    );
};



export default AddEditDialog;