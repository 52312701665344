import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../service/GetTemplate";
import { handlePatchRequest } from "../../service/PatchTemplete";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../service/PostTemplate";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";

const AddPromotion = ({ onHide, getPromotiondata, addEditPromotion, promotionRowData, rowDataId, promotiondata }) => {

    const [loading, setloading] = useState(false);
    const [subCategories, setSubCategories] = useState();
    const [products, setProducts] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const activeFrom = localStorage.getItem("activeFrom")
    const activeTo = localStorage.getItem("activeTo")
    const updatedDate = moment(activeTo).add(5, 'hours').toISOString();
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        subcategory: Yup.string().required("This field is required"),
        product: Yup.mixed().required("This field is required."),
        status: Yup.string().required("This field is required"),
        expireDate: Yup.mixed().required("This field is required."),
        discount: Yup.mixed().required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            subcategory: "",
            product: "",
            status: "",
            expireDate: "",
            discount: "",
        },
        onSubmit: async (data) => {
            setloading(true);
            if (parseInt(data.discount) > 100) {
                // You can display an error message or handle it as per your requirements
                toast("Discount cannot be greater than 100");
                return;
            }
            if (addEditPromotion === true) {

                data["campaignId"] = rowDataId;
                data["promotionId"] = promotionRowData;
                if (formik.values.expireDate === updatedDate.split('T')[0]) {
                    data["expireDate"] = updatedDate;
                }
                else {
                    data["expireDate"] = `${formik.values.expireDate}T23:59:00.779+00:00`;
                }
                console.log("data patch", data)

                const res = await dispatch(handlePatchRequest(data, "api/v1/promotion/updatePromotion", true, true));
                if (res.status === 200) {
                    await getPromotiondata();
                    formik.resetForm();
                    onHide();
                    setBtnDisable(true)
                }
                else {
                    setBtnDisable(false)
                }
                setBtnDisable(false)
            }

            else {
                data["campaignId"] = rowDataId;
                if (formik.values.expireDate === updatedDate.split('T')[0]) {
                    console.log("yes")
                    data["expireDate"] = updatedDate;
                }
                else {
                    console.log("no")
                    data["expireDate"] = `${formik.values.expireDate}T23:59:00.779+00:00`;
                }
                data.discount = data.discount;
                console.log("data post", data)

                const res = await dispatch(handlePostRequest(data, "api/v1/promotion/addPromotion", true, true));
                if (res?.status === 200 || res?.status === 201) {
                    await getPromotiondata();
                    formik.resetForm();
                    onHide();
                    setBtnDisable(true)
                } else {
                    setBtnDisable(false)
                    // toast.error(res.data.msg[0]);
                }
            }
            setBtnDisable(false)
            setloading(false);
        },
    });

    const getAllSubCategories = async () => {
        const response = await handleGetRequest("api/v1/subcategory/all", false);

        if (response) {
            setSubCategories(response);
        }
    };
    useEffect(() => {
        getAllSubCategories();
    }, []);

    const getAllProductsById = async () => {
        if (addEditPromotion === true) {
            const response = await handleGetRequest(`api/v1/subcategory/products?subCategoryId=${formik?.values?.subcategory}`, false);
            if (response) {
                setProducts(response?.products);
            }
        } else {
            const response = await handleGetRequest(`api/v1/subcategory/products?subCategoryId=${formik?.values?.subcategory}`, false);
            console.log("response", response)
            if (response) {
                setProducts(response?.products);
            }
        }
    };

    useEffect(() => {
        if (formik.values.subcategory !== "") {
            getAllProductsById();
        }
    }, [formik.values.subcategory]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (promotionRowData && addEditPromotion === true) {
            getPromotionByID();
        }
    }, [promotionRowData]);

    const statusOption = [
        { name: "Active", status: "active" },
        { name: "InActive", status: "inactive" },
    ];

    const getPromotionByID = async () => {

        if (promotionRowData) {
            console.log("promotionRowData", promotionRowData)
            formik.setFieldValue("subcategory", promotionRowData?.subcategory._id);
            formik.setFieldValue("expireDate", promotionRowData?.expireDate.split('T')[0]);
            formik.setFieldValue("status", promotionRowData?.status);
            formik.setFieldValue("discount", promotionRowData.discount);
            formik.setFieldValue("product", promotionRowData?.product?.map(item => item?._id));
        }
    };

    useEffect(() => {
        if (addEditPromotion) {
            
            setloading(true);
            const timer = setTimeout(() => {
                setloading(false);
            }, 2000);
            
            return () => clearTimeout(timer);
        }
    }, [addEditPromotion]);

    console.log("formik.values.product", formik.values.product)

    useEffect(() => {
        if (promotionRowData && addEditPromotion && formik?.values?.discount && formik.values.subcategory && formik.values.expireDate && formik?.values?.status) {
            if (promotionRowData?.subcategory._id === formik.values.subcategory && promotionRowData?.expireDate.split('T')[0] === formik.values.expireDate && promotionRowData?.status === formik.values.status && promotionRowData.discount === formik.values.discount) {
                console.log("yes")
                setBtnDisable(true)
            }
            else if (promotionRowData?.subcategory._id !== formik.values.subcategory || promotionRowData?.expireDate.split('T')[0] !== formik.values.expireDate || promotionRowData?.status !== formik.values.status || promotionRowData.discount !== formik.values.discount) {
                console.log("no")
                setBtnDisable(false)
            }
        }

    }, [promotionRowData, addEditPromotion, formik.values.subcategory, formik.values.expireDate, formik?.values?.status, formik?.values?.discount])

    return (
        <div>
            {loading ? (
                <ProgressSpinner style={{ display: "flex", justifyContent: "center", alignItem: "center", height: "50vh" }} strokeWidth="2" stroke-miterlimit="10" />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Select Sub-Category <span style={{ color: "red" }}>*</span>
                                </label>
                                <Dropdown
                                    placeholder="--Please Select--"
                                    name="subcategory"
                                    id="subcategory"
                                    className={classNames({ "p-invalid": isFormFieldValid("subcategory") }, "w-full md:w-10 inputClass")}
                                    value={formik?.values?.subcategory}
                                    onChange={formik.handleChange}
                                    optionLabel="name"
                                    optionValue="_id"
                                    options={subCategories}
                                />
                            </div>
                            {getFormErrorMessage("subcategory")}
                        </div>

                        {formik?.values?.subcategory ? (
                            <div className="col-12 md:col-12 xl:col-12 lg:col-12">
                                <div className="flex flex-column">
                                    <label className="mb-2">
                                        Select Product <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <MultiSelect
                                        // disabled={isDisable}
                                        filter
                                        display=""
                                        placeholder="--Please Select--"
                                        className="w-full md:w-10 inputClass"
                                        name="product"
                                        id="product"
                                        htmlFor="product"
                                        options={products}
                                        value={formik?.values?.product}
                                        optionLabel="name"
                                        optionValue="_id"
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage("product")}
                                </div>
                            </div>
                        ) : null}

                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Status <span style={{ color: "red" }}>*</span>
                                </label>
                                <Dropdown placeholder="--Please Select--" id="status" name="status" value={formik.values.status} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("status") }, "w-full md:w-10 inputClass")} options={statusOption} optionLabel="name" optionValue="status" />
                                {getFormErrorMessage("status")}
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-12 xs:col-12">
                            <div className="flex flex-column">
                                <label htmlFor="fromDate">
                                    Expire Date <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    format="dd/mm/yyyy"
                                    id="expireDate"
                                    name="expireDate"
                                    value={formik.values.expireDate}
                                    onChange={formik.handleChange}
                                    className={classNames({ "p-invalid": isFormFieldValid("expireDate") }, "w-full md:w-10 inputClass")}
                                    type="date"
                                    min={activeFrom?.split("T")[0]}
                                    max={activeTo?.split("T")[0]}
                                />
                            </div>
                            {getFormErrorMessage("expireDate")}
                        </div>
                        <div className="col-12 md:col-12 xl:col-12 lg:col-12">
                            <div className="flex flex-column">
                                <label className="mb-2">
                                    Discount % <span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText
                                    maxLength={2}
                                    keyfilter="num"
                                    type="text"
                                    name="discount"
                                    id="discount"
                                    value={formik.values.discount}
                                    onChange={formik.handleChange}
                                    onKeyPress={(e) => {
                                        // Allow only digits
                                        const isValid = /^\d+$/.test(e.key);
                                        if (!isValid) {
                                            e.preventDefault();
                                        }
                                    }}
                                    className={classNames({ "p-invalid": isFormFieldValid("discount") }, "w-full md:w-10 inputClass")}
                                />
                                {getFormErrorMessage("discount")}
                            </div>
                        </div>

                        <div className="col-12 md:col-12 xl:col-12 lg:col-12 text-center">
                            <Button label="Cancel" onClick={onHide} type="button" className="Cancelbtn p-mr-3" />
                            <Button
                                className="Savebtn"
                                label={addEditPromotion ? "Update" : "Save"}
                                // disabled={btnDisable}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AddPromotion;
